// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrlAdmin: 'https://www.dev-api.sukoun.design',
  clientUrl: 'https://www.dev-site.sukoun.design/en/',
  // baseUrlAdmin:'http://54.82.203.84:4000',
  defaultauth: 'fake-backend',
  firebaseConfig: {
    apiKey: "AIzaSyAljWT10yNNFFWnzIgh0jGAAqUY6BX0y28",
    authDomain: "kalba-4faf4.firebaseapp.com",
    projectId: "kalba-4faf4",
    storageBucket: "kalba-4faf4.appspot.com",
    messagingSenderId: "466348407436",
    appId: "1:466348407436:web:9d6887625e12ad983c19a9",
    measurementId: "G-57D5RQTNQS",
    vapidKey: "BObVxmReW8xpS7sa514a7OePRrlfJhx75a2BGHqoWO00gT26rvfgwsOTA9NjsW2yFk1o-40FRpP0mL8-Rf7kPJs"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
