<!-- <div class="card"> -->
<div *ngIf="applications" class="mt-3">
  <div class="title-applications">
      <span class="text-muted mb-3 pb-3 lh-1 d-block text-truncate">
        <h4> {{ 'PENDING_REQUESTS' | translate }}
          <b>
            (<span [CountTo]="applicationsCount" [duration]="0.1" [from]="0" class="counter-value"></span>)
          </b>
        </h4>
      </span>
  </div>
  <div class="table-responsive">
    <table class="table table-hover mb-0">

      <thead>
      <tr>
        <th>{{ 'ID#' | translate }}</th>
        <!--          <th>{{ 'USERNAME' | translate }}</th>-->
        <th>{{ 'SERVICE' | translate }}</th>
        <th>{{ 'TYPE' | translate }}</th>
        <!--          <th>{{ 'EMAIL' | translate }}</th>-->
        <th>{{ 'NAME' | translate }}</th>
        <th>{{ 'MESSAGE' | translate }}</th>
        <th>{{ 'LAST_UPDATED' | translate }}</th>
        <th>{{ 'STATUS' | translate }}</th>
        <!--        <th>{{ 'ACTION' | translate }}</th>-->
      </tr>
      </thead>
      <tbody>
      <tr (click)="seeDetails(application)" *ngFor="let application of applications" id="table-row"
          style="vertical-align: baseline;">
        <th scope="row">{{application?.id}}</th>
        <td>{{application?.service.name | translatePipe : currentLanguage :'translation' |
          slice:0:20}}{{(application?.service.name | translatePipe : 'EN':'translation').length > 20 ? '...' : ''}}</td>
        <td>{{(application?.service?.typeId == 2
          ? "BUSINESS"
          : application?.service?.typeId == 1
            ? "GOVERNMENT"
            : "INDIVIDUAL") | translate
          }}</td>
        <!--          <td title="{{application?.requester.firstName}}">{{application?.requester.firstName+ ' '+ application?.requester.lastName}}</td>-->
        <td>{{application?.requester?.company?.companyName}}</td>
        <!--          <td>{{application?.email}}</td>-->
        <td><span title="{{application?.approvers[0]?.message}}">{{application?.approvers[0]?.message &&
        application?.approvers[0]?.message.length > 12 ? (application?.approvers[0]?.message |
          slice:0:12) + '...' : application?.approvers[0]?.message}}</span></td>
        <td>{{application?.updatedAt | date: 'dd.MM.yyyy'}}</td>
        <td>
          <!--          {{(application?.status?.status | uppercase | replace:' ':'_') | translate}}-->
          <span [ngClass]="{
                    'bg-warning': application?.status?.id == 21,
                    'bg-info': application?.status?.id == 13,
                    'bg-success': application?.status?.id == 6 || application?.status?.id == 43,
                    'bg-primary': application?.status?.id == 15,
                    'bg-danger': application?.status?.id == 11,
                    'bg-secondary': application?.status?.id == 4,
                    'bg-soft-danger': application?.status?.id == 10,
                    'bg-soft-secondary text-success': application?.status?.id == 19,
                    'bg-processing text-dark': application?.status?.id == 30,
                    'bg-pending' : application?.status?.id == 3 || application?.status?.id == 31
                  }" class="badge ms-1">
                      {{
            application?.status?.status
              | uppercase
              | replace : " " : "_"
              | translate
            }}
                    </span>
        </td>
        <!--        <td>-->
        <!--          <button type="button" (click)="seeDetails(application)"-->
        <!--                  class="btn btn-sm btn-soft-primary waves-effect waves-light">-->
        <!--            {{ 'REVIEW' | translate }}-->
        <!--          </button>-->
        <!--        </td>-->
      </tr>
      </tbody>
    </table>
    <div *ngIf="!applications || applications.length==0 && loader">
      <app-bar-loader [limit]="10"></app-bar-loader>
    </div>
    <div *ngIf="!applications || applications.length==0 && !loader">
      {{ 'NO_RESULT_FOUND' | translate }}
    </div>
    <div class="row justify-content-md-between align-items-md-center mt-2"
         style="width: 100%;">
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">
          <div class="dataTables_length" id="tickets-table_length"><label class="d-flex align-items-center">
            <label>{{ 'SHOW_ENTRIES' | translate }}</label>
            <select (change)="fetchData()" [(ngModel)]="pagination.limit" aria-controls="tickets-table"
                    class="form-control form-control-sm mx-2"
                    name="pageSize" name="tickets-table_length" style="width: 30%">
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select></label></div>
        </div>
      </div>
      <!-- Pagination -->
      <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end pagination-rounded">
          <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.pageNo"
                          [collectionSize]="totalCount" [maxSize]="5" [pageSize]="pagination.limit">
          </ngb-pagination>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</div>
<!-- </div> -->
