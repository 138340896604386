import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../core/services/api.service";
import {Router} from "@angular/router";
import {requests} from "../../../core/config/config";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'pending-approval-appliations',
  templateUrl: './pending-approval-appliations.component.html',
  styleUrls: ['./pending-approval-appliations.component.scss']
})
export class PendingApprovalAppliationsComponent implements OnInit {
  @Input() applications: any = [];
  @Input() applicationsCount: number;
  totalCount: any;
  currentUser: any;
  loader: boolean;
  filterModel: { statusId?: number, isPending?: boolean, assignedTo?: any } = {isPending: true}
  currentLanguage: string = "EN";
  pagination: { pageNo: number, limit: number, offset: number, typeId?: number, statusId?: number } = {
    pageNo: 1,
    limit: 10,
    offset: 0
  }

  constructor(private apiService: ApiService,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang.toUpperCase();
    });
  }

  fetchData() {
    let user = JSON.parse(localStorage.getItem('admin'));
    this.currentUser = user.user?.id;
    // this.filterModel.assignedTo = this.currentUser;
    this.apiService.sendRequest(requests.getPendingApprovalApplications, 'get', {isPending: true}).subscribe((res: any) => {
      this.applications = res?.data.rows;
      this.applicationsCount = res?.data.count;
    });
  }

  seeDetails(application: any) {
    this.router.navigate(['/applications/details/' + application?.id])
  }

  onPageChange(event: any) {
    this.pagination.pageNo = event;
    // let page = this.pagination.pageNo == 1 ? 0 : this.pagination.pageNo - 1;
    this.pagination.offset = event == 1 ? 0 : (event - 1) * this.pagination.limit;
    this.fetchData()
  }


}
