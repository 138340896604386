<div class="row">
  <div class="col-xl-12">
    <!-- card -->
    <div class="card">
      <!-- card body -->
      <div class="card-body">
        <h5 class="card-title me-2">{{ 'USERS_OVERVIEW' | translate }}</h5>
        <!-- <div class="d-flex flex-wrap align-items-center mb-4">
          <div class="ms-auto">
            <div>
              <button type="button" class="btn btn-soft-primary btn-sm">
                ALL
              </button>
              <button type="button" class="btn btn-soft-secondary btn-sm ms-1">
                1M
              </button>
              <button type="button" class="btn btn-soft-secondary btn-sm ms-1">
                6M
              </button>
              <button type="button" class="btn btn-soft-secondary btn-sm active ms-1">
                1Y
              </button>
            </div>
          </div>
        </div> -->

        <div class="row align-items-center">
          <div *ngIf="marketOverview" class="col-xl-6">
            <div>
              <div class="row">

                <div *ngIf="walletOverview" class="col-sm">
                  <!--            <apx-chart dir="ltr" class="apex-charts" [series]="walletOverview.series"-->
                  <!--                       [chart]="walletOverview.chart" [legend]="walletOverview.legend"-->
                  <!--                       [colors]="walletOverview.colors" [labels]="walletOverview.labels"-->
                  <!--                       [stroke]="walletOverview.stroke" [plotOptions]="walletOverview.plotOptions">-->
                  <!--            </apx-chart>-->
                </div>

                <div class="col-sm align-self-center">
                  <div class="mt-4 mt-sm-0">
                    <div>
                      <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2"
                                         style="color: #a8aada;"></i>
                        {{ 'GOVERNMENT' | translate }} <span
                          class=" p-2 font-size-14 fw-bold">{{getUserCount('GOVERNMENT')}}</span>
                      </p>
                      <!-- <h6>{{getUserCount('GOVERNMENT')}} <span class="text-muted font-size-14 fw-normal"></span></h6> -->
                    </div>

                    <div class="mt-2 pt-2 ">
                      <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2"
                                         style="color: #777aca;"></i>
                        {{ 'INDIVIDUAL' | translate }} <span
                          class=" p-2 font-size-14 fw-bold">{{getUserCount('INDIVIDUAL')}}</span>
                      </p>
                      <!-- <h6>{{getUserCount('INDIVIDUAL')}} <span class="text-muted font-size-14 fw-normal" style="color: #5156be;"></span></h6> -->
                    </div>

                    <div class="mt-2 pt-2 ">
                      <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i>
                        {{ 'BUSINESS' | translate }} <span
                          class=" p-2 font-size-14 fw-bold">{{getUserCount('BUSINESS')}}</span>
                      </p>
                      <!-- <h6>{{getUserCount('BUSINESS')}} <span class="text-muted font-size-14 fw-normal"></span></h6> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="userStats" class="col-xl-6">


            <div class="p-3">
              <b>{{ 'USERS_COUNT_BY_USER_TYPE' | translate }}</b>
              <div *ngFor="let stats of userStats?.usersCountByUserType" class="mt-0 mb-2">
                <div class="d-flex align-items-center">
                  <!-- <div class="avatar-sm m-auto">
                      <span class="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                          {{stats?.count}}
                      </span>
                  </div> -->
                  <div class="flex-grow-1 ms-3">
                    <span
                      class="font-size-16">{{stats?.userType?.type | uppercase | replace:' ':'_' | translate }}</span>
                  </div>

                  <div class="flex-shrink-0">
                                  <span
                                    class="badge rounded-pill badge-soft-success font-size-12 fw-medium">{{stats?.count}}
                                  </span>
                  </div>
                </div>
              </div>
              <b>{{ 'USERS_COUNT_BY_STATUS' | translate }}</b>
              <div *ngFor="let stats of userStats?.userCountByStatus" class="mt-0">
                <div class="d-flex align-items-center">
                  <!-- <div class="avatar-sm m-auto">
                      <span class="avatar-title rounded-circle bg-soft-light text-dark font-size-16">

                      </span>
                  </div> -->
                  <div class="flex-grow-1 ms-3">
                    <span
                      class="font-size-16">{{stats?.status?.status | uppercase | replace:' ':'_' | translate }}</span>
                  </div>

                  <div class="flex-shrink-0">
                                  <span class="badge rounded-pill badge-soft-success font-size-12 fw-medium">
                                      {{stats?.count}}</span>
                  </div>
                </div>
              </div>


              <!-- <div class="mt-4 pt-2">
                  <a href="" class="btn btn-soft-primary w-100">See All Balances <i
                          class="mdi mdi-arrow-right ms-1"></i></a>
              </div> -->

            </div>
          </div>
        </div>

      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row-->
  <!-- end col -->
</div>

