import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({providedIn: 'root'})
export class LanguageService {

  public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];

  constructor(public translate: TranslateService, private cookieService: CookieService) {

    let browserLang;
    /**
     * cookie Language Get
     */
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : 'en');
  }

  /**
   * Cookie Language set
   */
  public setLanguage(lang: string) {
    // Set the language for translation
    this.translate.use(lang);

    // Remove existing cookies if they exist
    ['lang', 'dir'].forEach((key) => {
      if (this.cookieService.check(key)) {
        this.cookieService.delete(key);
      }
    });

    // Set new cookies
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    this.cookieService.set('lang', lang, 30);
    this.cookieService.set('dir', dir, 30);
  }


}
