export enum StatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  REJECTED = 4,
  APPROVED = 5,
  COMPLETED = 6,
  CANCELLED = 7,
  VERIFIED = 8,
  DRAFT = 9,
  PENDING_APPROVAL = 10,
  INCOMPLETE_INFO = 11,
  PUBLISHED = 12,
  NEW = 13,
  VIEWED = 14,
  IN_PROGRESS = 15,
  ON_HOLD = 16,
  CLOSED = 17,
  SENT = 18,
  EXPIRED = 19,
  PAYMENT_FAILED = 20,
  PAYMENT_PENDING = 21,
  RESUBMITTED = 22,
  WAITING_FOR_RESPOND = 23,
  INTERNAL_REVIEW = 24,
  DOCUMENTS_VERIFIED = 25,
  NEW_INTERNAL_STATUS = 26,
  DELETED_USER = 29,
  PROCESSING = 30,
  UNDER_REVIEW = 31,
  PENDING_REVIEW = 32,
  PAID = 33,
  ASSIGNED = 34,
  REQUESTED_INFO_UPDATE = 35,
  UPDATED_INFO = 36,
  REQUESTED_PAYMENT = 37,
  COMPLETED_PAYMENT = 38,
  REQUESTED_PROCESSING = 39,
  REQUESTED_REVIEW = 40,
  REQUESTED_REVISION = 41,
  COMPLETED_SERVICE_PROCESSING = 42,
  PROCESSED = 43,
  LINK_GENERATED = 44
}
