<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        ©  {{year}} {{ 'KALBA_MUNICIPALITY' | translate }}
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          <!-- Design & Develop by <a href="javascript:void(0);" class="text-decoration-underline">Gazeez</a> -->
        </div>
      </div>
    </div>
  </div>
</footer>
