import {environment} from "../../../environments/environment";

export const requests = {
  addAdmins: environment.baseUrlAdmin + '/admin/accounts/admin-user',
  addDestination: environment.baseUrlAdmin + '/admin/destination',
  addEvent: environment.baseUrlAdmin + '/admin/events',
  addHoliday: environment.baseUrlAdmin + '/admin/holiday',
  addNews: environment.baseUrlAdmin + '/admin/news',
  addRole: environment.baseUrlAdmin + '/admin/role',
  applicationStats: environment.baseUrlAdmin + '/admin/analytics/applicationsCount',
  approveUser: environment.baseUrlAdmin + '/admin/company/approve/',
  delAdmins: environment.baseUrlAdmin + '/admin/accounts/admin-user/',
  deleteAdmin: environment.baseUrlAdmin + '/admin/accounts/admin-user/',
  deleteBus: environment.baseUrlAdmin + '/admin/accounts/business/',
  deleteDestination: environment.baseUrlAdmin + '/admin/destination/',
  deleteEvent: environment.baseUrlAdmin + '/admin/events/',
  deleteGov: environment.baseUrlAdmin + '/admin/accounts/government/',
  deleteHoliday: environment.baseUrlAdmin + '/admin/holiday/',
  deleteIndividualUser: environment.baseUrlAdmin + '/individual-profile',
  deleteNews: environment.baseUrlAdmin + '/admin/news/',
  getAdminById: environment.baseUrlAdmin + '/admin/accounts/admin-user/',
  getAdmins: environment.baseUrlAdmin + '/admin/accounts/admin-user?userTypeIds[]=3&userTypeIds[]=5',
  getAllPermisions: environment.baseUrlAdmin + '/admin/permissions',
  getBusById: environment.baseUrlAdmin + '/admin/accounts/business/',
  getBusinessAccounts: environment.baseUrlAdmin + '/admin/company',
  getCoverImages: environment.baseUrlAdmin + '/admin/community/header-images',
  getDepartments: environment.baseUrlAdmin + '/admin/department',
  getDestinationById: environment.baseUrlAdmin + '/admin/destination/',
  getDestinations: environment.baseUrlAdmin + '/admin/destination',
  getEventById: environment.baseUrlAdmin + '/admin/events/',
  getEvents: environment.baseUrlAdmin + '/admin/events',
  getGovById: environment.baseUrlAdmin + '/admin/accounts/government/',
  getGovernmentAccounts: environment.baseUrlAdmin + '/admin/company',
  getGovernmentEntities: environment.baseUrlAdmin + '/admin/govt-entity?limit=100&offset=0',
  getHolidayById: environment.baseUrlAdmin + '/admin/holiday/',
  getHolidays: environment.baseUrlAdmin + '/admin/holiday',
  getIndividualAccounts: environment.baseUrlAdmin + '/admin/user',
  getIndividulaAccount: environment.baseUrlAdmin + '/individual-profile/',
  getModules: environment.baseUrlAdmin + '/admin/module',
  getNews: environment.baseUrlAdmin + '/admin/news',
  getNewsById: environment.baseUrlAdmin + '/admin/news/',
  getPermisionsById: environment.baseUrlAdmin + '/admin/permissions/user/',
  getPresignedUrl: environment.baseUrlAdmin + '/document/preSignedUrl',
  getRoles: environment.baseUrlAdmin + '/admin/role',
  instantReportsStats: environment.baseUrlAdmin + '/admin/analytics/instantReportCount',
  landingPageVideo: environment.baseUrlAdmin + '/landing-page/video',
  login: environment.baseUrlAdmin + '/auth/login',
  logout: environment.baseUrlAdmin + 'authentication/logout',
  postCoverImages: environment.baseUrlAdmin + '/admin/community/header-image',
  postSignedUrl: environment.baseUrlAdmin + '/document/postSignedUrl',
  publishDestination: environment.baseUrlAdmin + '/admin/destination/',
  publishEvent: environment.baseUrlAdmin + '/admin/events/',
  publishHoliday: environment.baseUrlAdmin + '/admin/holiday/',
  publishNews: environment.baseUrlAdmin + '/admin/news/',
  rejectGovernment: environment.baseUrlAdmin + '/admin/accounts/government/reject-government-company/',
  rejectbusiness: environment.baseUrlAdmin + '/admin/accounts/business/reject-business-company/',
  sendAccountFeedback: environment.baseUrlAdmin + '/admin/user/profile-feedback',
  serviceStats: environment.baseUrlAdmin + '/admin/analytics/servicesCount',
  siteSettings: environment.baseUrlAdmin + '/admin/help-center',
  userStats: environment.baseUrlAdmin + '/admin/analytics/usersCount',

  // categories
  addServiceCategory: environment.baseUrlAdmin + '/admin/categories',
  createGovernment: environment.baseUrlAdmin + '/admin/accounts/government/create',
  deleteServiceCategory: environment.baseUrlAdmin + '/admin/categories/',
  getCompaintsAndSugestions: environment.baseUrlAdmin + '/admin/complaints-suggestions',
  getCreateServices: environment.baseUrlAdmin + '/admin/service',
  getExpressions: environment.baseUrlAdmin + '/admin/experiences',
  getGovernmentDocsConfig: environment.baseUrlAdmin + '/client/company/document-type?companyType=1',
  getInstantreports: environment.baseUrlAdmin + '/admin/instant-report',
  getInstantreportsById: environment.baseUrlAdmin + '/admin/instant-report/',
  getServiceById: environment.baseUrlAdmin + '/admin/service/',
  getServiceCategory: environment.baseUrlAdmin + '/admin/categories',
  getServiceRequestType: environment.baseUrlAdmin + '/admin/service/type/service-request-types',
  getUpdateDelServiceByid: environment.baseUrlAdmin + '/admin/service/',
  // applications
  applicationLogs: environment.baseUrlAdmin + '/admin/application-logs',
  approveRequest: environment.baseUrlAdmin + '/admin/application-approvers/approved/',
  assignApplication: environment.baseUrlAdmin + '/admin/application/',
  changeApplicationStatus: environment.baseUrlAdmin + '/admin/application/',
  createApprovalRequest: environment.baseUrlAdmin + '/admin/application-approvers',
  exportApplications: environment.baseUrlAdmin + '/admin/application?isExport=true',
  getAllAdminUsers: environment.baseUrlAdmin + '/admin/accounts/admin-user?userTypeIds[]=3&userTypeIds[]=5',
  getAllApplications: environment.baseUrlAdmin + '/admin/application',
  getAllDepartments: environment.baseUrlAdmin + '/admin/department',
  getAllUsers: environment.baseUrlAdmin + '/admin/accounts/admin-user/',
  getApplicationDetailsByid: environment.baseUrlAdmin + '/admin/application/',
  getApplicationFeedback: environment.baseUrlAdmin + '/admin/application-feedback',
  getApplicationReview: environment.baseUrlAdmin + '/admin/application-review',
  getPendingApprovalApplications: environment.baseUrlAdmin + '/admin/application',
  sendApplicationFeedback: environment.baseUrlAdmin + '/admin/application-review',
  requestProcessing: environment.baseUrlAdmin + '/admin/application-approvers/',
  // About kalba
  aboutKalba: environment.baseUrlAdmin + '/admin/about-kalba',

  // FAQ
  deleteFAQ: environment.baseUrlAdmin + '/admin/faq/',
  frequentQuestions: environment.baseUrlAdmin + '/admin/faq',
  getAllFaq: environment.baseUrlAdmin + '/admin/faq',
  updateFAQ: environment.baseUrlAdmin + '/admin/faq/',

  // Office Locations (Addresses)
  createLocation: environment.baseUrlAdmin + '/admin/office-location',
  getAllLocation: environment.baseUrlAdmin + '/admin/office-location',

  // Notifications Api
  getAllNotifications: environment.baseUrlAdmin + '/notification',
  markAsRead: environment.baseUrlAdmin + '/admin/notification/mark-as-read/',
  markAllAsRead: environment.baseUrlAdmin + '/admin/notification/mark-all-as-read',

  // Company-Type CRUD Api
  createCompanyType: environment.baseUrlAdmin + '/admin/company-type',
  getAllCompanyTypes: environment.baseUrlAdmin + '/admin/company-type',
  updateCompanyType: environment.baseUrlAdmin + '/admin/company-type/',

  // License-Authorities-Emirates CRUD Api
  createlicenseAuthoritiesEmirates: environment.baseUrlAdmin + '/admin/license-emirates-authority',
  getAllLicenseAuthorities: environment.baseUrlAdmin + '/admin/license-emirates-authority',
  updatelicenseAuthoritiesEmirates: environment.baseUrlAdmin + '/admin/license-emirates-authority/',

  // License-Authorities (States) CRUD Api
  createlicenseStates: environment.baseUrlAdmin + '/states',
  getAlllicenseStates: environment.baseUrlAdmin + '/states?countryId=2',
  updatelicenseStates: environment.baseUrlAdmin + '/states/',

  // Government Entity CRUD
  createGovernanceEntities: environment.baseUrlAdmin + '/admin/govt-entity',
  deleteGovernmentEntities: environment.baseUrlAdmin + '/admin/govt-entity/',
  getAllGovernmentEntities: environment.baseUrlAdmin + '/admin/govt-entity',
  updateGovernmentEntities: environment.baseUrlAdmin + '/admin/govt-entity/',

  // Internal Application Statuses
  createInternalStatus: environment.baseUrlAdmin + '/admin/application-has-status',
  deleteInternalStatus: environment.baseUrlAdmin + '/admin/application-has-status/',
  getAllInternalStatuses: environment.baseUrlAdmin + '/admin/application-has-status',
  getInternalStatusById: environment.baseUrlAdmin + '/admin/application-has-status/',
  updateInternalStatus: environment.baseUrlAdmin + '/admin/application-has-status/',

  // Payment Api
  createPayment: environment.baseUrlAdmin + '/admin/payment',
  getPayment: environment.baseUrlAdmin + '/admin/payment',
  sendPaymentMail: environment.baseUrlAdmin + '/admin/payment/',
  cancelPayment: environment.baseUrlAdmin + '/admin/payment/',

  // application attachemtns
  attachDocument: environment.baseUrlAdmin + '/admin/application/attach-doc',
  pdfTemplateApi: environment.baseUrlAdmin + '/admin/dynamic-certificate',
  deleteProcessingDocuments: environment.baseUrlAdmin + '/document/',

  // gov 1 bus 2
  // updatePassword: environment.baseUrlAdmin + 'authentication/password/update',
  // resetPassword: environment.baseUrlAdmin+'authentication/request/password/reset',
  // setPassword: environment.baseUrlAdmin+'authentication/password/reset/',
  // requestPasswordReset: environment.baseUrlAdmin + 'authentication/request/password/reset',
  addNewAttachment: environment.baseUrlAdmin + 'attachments',

  //Auth
  changePassword: environment.baseUrlAdmin + '/admin/accounts/admin-user/update-password',
  forgotPassword: environment.baseUrlAdmin + '/admin/accounts/admin-user/forgot-password',
  getProfile: environment.baseUrlAdmin + '/auth/profile',
  updatePassword: environment.baseUrlAdmin + '/admin/accounts/admin-user/update-password',

  //User Session
  createUserSession: environment.baseUrlAdmin + '/user-session',
  deleteUserSession: environment.baseUrlAdmin + '/user-session/logout',

  // chatbot service
  exposeService: environment.baseUrlAdmin + '/admin/service/expose-service-to-chatbot/',
  unexposeService: environment.baseUrlAdmin + '/admin/service/unexpose-service-to-chatbot/',

  //Get Countries
  getAllCounries: environment.baseUrlAdmin + '/countries',

  //Instant Reports
  assignReport: environment.baseUrlAdmin + '/admin/instant-report/',
  getInstantreportById: environment.baseUrlAdmin + '/admin/instant-reports/details/',
  //Complaints and Suggestions
  assignComplaintAndSuggestion: environment.baseUrlAdmin + '/admin/complaints-suggestions/',
  getComplaintAndSuggestionById: environment.baseUrlAdmin + '/admin/complaints-suggestions/',
  getCountComplaintsAndSuggestion: environment.baseUrlAdmin + '/admin/analytics/complaintsAndSuggestionCount',
  //Customers
  getAllCustomers: environment.baseUrlAdmin + '/admin/user/customers',
  getCountAllCustomers: environment.baseUrlAdmin + '/admin/analytics/usersCount',
  // Tehseel Services
  createTehseelService: environment.baseUrlAdmin + '/admin/tahseel-service',
  deleteTehseelService: environment.baseUrlAdmin + '/admin/tahseel-service/',
  getAllTehseelServices: environment.baseUrlAdmin + '/admin/tahseel-service',
  getTehseelServiceById: environment.baseUrlAdmin + '/admin/tahseel-service/',
  updateTehseelService: environment.baseUrlAdmin + '/admin/tahseel-service/',

  // External APIS
  prayerTime: "https://api.aladhan.com/v1/timingsByCity/",
  weatherApi: 'https://api.openweathermap.org/data/2.5/weather?lat=25.0579&lon=56.3546&appid=2a5e0a10b8f4e18d6eafeb945c293742&units=metric'

}

export const patterns = {
  url: '(https?://).*?'
}
