import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetModule } from './widget/widget.module';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { ToastsContainer } from './toasts-container.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AssignToComponent } from './assign-to/assign-to.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommentsComponent } from './app-comments/app-comments.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TranslateModule } from '@ngx-translate/core';
import {Translate} from "./pipes/translation.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";

@NgModule({
  declarations: [
    ToastsContainer,
    PagetitleComponent,
    Translate,
    AppCommentsComponent,
    ReplacePipe



  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    WidgetModule,
    UiSwitchModule,
    ReactiveFormsModule,
    AssignToComponent,
    SimplebarAngularModule
  ],
  exports: [PagetitleComponent,ToastsContainer,AppCommentsComponent, TranslateModule, Translate, ReplacePipe]
})
export class SharedModule { }
