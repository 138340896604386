<!-- <ng-template ngbNavContent> -->
    <div class="card">
        <ngx-simplebar class="chat-conversation p-3 px-2">

            <ul class="list-unstyled mb-0">
                <!-- <li class="chat-day-title">
                    <span class="title">Today</span>
                </li> -->
                <li *ngFor="let data of chatMessagesData"
                    [ngClass]="{'right': data.align === 'right' }">
                    <div class="conversation-list" [ngClass]="{'order-3': data.align === 'right' }">
                        <div class="ctext-wrap d-flex">
                            <div class="ctext-wrap-content">
                                <h5 class="conversation-name"><a href="javascript:void(0)"
                                        class="user-name">{{data.name}}</a>
                                    <span class="time">{{data.time | date:'short'}}</span>
                                </h5>
                                <p class="mb-0">{{ data.message }}</p>
                            </div>
                            <!-- <div class="dropdown align-self-start" ngbDropdown>
                                <a class="dropdown-toggle" href="javascript:void(0);" role="button"
                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" ngbDropdownToggle>
                                    <i class="mdi mdi-dots-vertical"></i>
                                </a>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <a class="dropdown-item" href="javascript:void(0);">Copy</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Forward</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </li>
            </ul>
        </ngx-simplebar>
        <div class="p-3 border-top">
            <div class="row">
                <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
                    <div class="p-3 chat-input-section">
                        <div class="row">
                            <div class="col">
                                <div class="position-relative">
                                    <input formControlName="message"
                                        [ngClass]="{'is-invalid': chatSubmit && form.message.errors}"
                                        type="text" class="form-control chat-input rounded"
                                        placeholder="{{ 'MESSAGE' | translate }}" />
                                    <div *ngIf="chatSubmit && form.message.errors"
                                        class="invalid-feedback">
                                        <span *ngIf="form.message.errors.required">This value is
                                            required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button type="submit" [disabled] = "allowSendingComment"
                                    class="btn btn-soft-primary chat-send w-md waves-effect waves-light">
                                    <span class="d-none d-sm-inline-block me-2">{{ 'SEND' | translate }}</span>
                                    <i class="mdi mdi-send float-end"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>

<!-- </ng-template> -->
