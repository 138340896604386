import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-media-team-dashboard',
  templateUrl: './media-team-dashboard.component.html',
  styleUrls: ['./media-team-dashboard.component.scss']
})
export class MediaTeamDashboardComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
