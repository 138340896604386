
   <div id="preloader" *ngIf="commonStore.loader" style="background: aliceblue; opacity: 0.6;z-index: 10000;">
    <!-- <div class="spinner-grow text-primary m-1" role="status">
        <span class="sr-only">Loading...</span>
    </div> -->
  </div>
<!-- alerts -->
 <div *ngIf="(commonStore.alertArray && commonStore.alertArray.length > 0)" class="top-alert">
    <div class="d-flex justify-content-center" *ngFor="let alert of commonStore.alertArray" (click)="closeAlert(alert)">
      <!-- <button type="button" class="close" (click)="closeAlert(alert)"><span area-hidden="true">&times;</span></button> -->
      <div *ngIf="alert.isOpen" [ngClass]="'toastr-'+alert.case" class="toastr p-3">

        <div class="row" *ngIf="alert.case=='success'">
          <div class="col-sm-3">
            <i class="mdi mdi-check-circle-outline icon-tick" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-9 text-left">
            <h5 class="mb-0">Operation Completed Successfully</h5>
            <p class="mb-0" *ngIf="alert && alert.message && alert.local">
              {{alert.message}}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="alert.case=='error'">
          <div class="col-sm-3">
            <i class="mdi mdi-alert" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-9 text-left">
            <h4 class="mb-0">Error Occured</h4>
            <p class="mb-0" *ngIf="alert && alert.message">
              {{alert.message}}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="alert.case=='info'">
          <div class="col-sm-3">
            <i class="mdi-alert-circle-outline" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-9 text-left">
            <h3 class="mb-0">Info</h3>
            <p class="mb-0" *ngIf="alert && alert.message">
              {{alert.message}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- Vertical layout -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

<!-- horizontal layout -->
<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>
