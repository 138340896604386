import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import {Router} from "@angular/router";
import {ApiService} from "../api.service";
import {requests} from "../config/config";
import {CommonStore} from "./common.store";

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

  user!: User;
  currentUserValue: any;

  constructor(private router: Router,
              private apiService: ApiService,
              private commonStore: CommonStore,) { }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    return getFirebaseBackend()!.registerUser(email, password).then((response: any) => {
      const user = response;
      return user;
    });
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return getFirebaseBackend()!.loginUser(email, password).then((response: any) => {
      const user = response;
      return user;
    });
  }

  /**
   * Returns the current user
   */
  public currentUser(): any {
    return getFirebaseBackend()!.getAuthenticatedUser();
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    this.apiService.sendRequest(requests.deleteUserSession, 'delete').subscribe( () => {
      localStorage.clear()
      // return getFirebaseBackend()!.logout();
      this.router.navigate(['/account/login'], { queryParams: { session: 'logout' } });
    });

  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend()!.forgetPassword(email).then((response: any) => {
      const message = response.data;
      return message;
    });
  }

}

