<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.jpg" alt="" height="24">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/logo.jpg" alt="" height="24"> <span
            class="logo-txt">{{ 'SMART_KALBA' | translate }}</span>
                    </span>
        </a>

        <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo-light.jpg" alt="" height="24">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/logo-light.jpg" alt="" height="24"> <span
            class="logo-txt">{{ 'SMART_KALBA' | translate }}</span>
                    </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search...">
              <button class="btn btn-soft-primary" type="button"><i class="bx bx-search-alt align-middle"></i></button>
          </div>
      </form> -->
    </div>

    <div class="d-flex">

      <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-search-dropdown" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i-feather name="search" class="icon-lg"></i-feather>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

              <form class="p-3">
                  <div class="form-group m-0">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search ..."
                              aria-label="Search Result">

                          <button class="btn btn-soft-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                      </div>
                  </div>
              </form>
          </div>
      </div> -->

      <div class="dropdown d-none d-sm-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
          <span class="">{{countryName == 'English' ? 'العربية' : 'English' }}</span>
          <!-- <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16"> -->
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
             (click)="setLanguage(item.text, item.lang, item.flag)"
             [ngClass]="{'active': cookieValue === item.lang}">
            <!-- <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span> -->
            <span
              class="align-middle">{{item.text | uppercase | translate}}</span>
          </a>
        </div>
      </div>

      <!-- <div class="dropdown d-none d-sm-inline-block">
          <button type="button" class="btn header-item" id="mode-setting-btn">
              <ng-container *ngIf="layoutMode == 'light'">
                  <i-feather name="moon" class="icon-lg" (click)="changeMode('dark')"></i-feather>
              </ng-container>
              <ng-container *ngIf="layoutMode == 'dark'">
                  <i-feather name="sun" class="icon-lg" (click)="changeMode('light')"></i-feather>
              </ng-container>
          </button>
      </div> -->

      <!-- <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
          <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" ngbDropdownToggle>
              <i-feather name="grid" class="icon-lg"></i-feather>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
              <div class="p-2">
                  <div class="row g-0">
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/github.png" alt="Github">
                              <span>GitHub</span>
                          </a>
                      </div>
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                              <span>Bitbucket</span>
                          </a>
                      </div>
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/dribbble.png" alt="dribbble">
                              <span>Dribbble</span>
                          </a>
                      </div>
                  </div>

                  <div class="row g-0">
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/dropbox.png" alt="dropbox">
                              <span>Dropbox</span>
                          </a>
                      </div>
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                              <span>Mail Chimp</span>
                          </a>
                      </div>
                      <div class="col">
                          <a class="dropdown-icon-item" href="javascript:void(0)">
                              <img src="assets/images/brands/slack.png" alt="slack">
                              <span>Slack</span>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" (click)="getNotification()" class="btn header-item noti-icon position-relative"
                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
          <i-feather name="bell" class="icon-lg"></i-feather>
          <span *ngIf="notifications?.unreadCount > 0">
                    <span class="badge bg-danger rounded-pill">{{notifications?.unreadCount}}</span>
                    </span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
             aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0"> {{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <!--  <div class="col-auto">
                    <a href="javascript:void(0);" class="small text-reset text-decoration-underline"> {{
                        'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
                </div> -->
            </div>
          </div>
          <ngx-simplebar style="max-height: 230px;">
            <a [routerLink]="goToPage(notification)" class="text-reset notification-item"
               *ngFor="let notification of notificationDetails">
              <div class="d-flex" (click)="markAsRead(notification.id)">
                <div class="flex-shrink-0 me-3">
                  <i-feather name="bell" class="icon-lg"></i-feather>
                  <!-- <img src="assets/images/users/avatar-3.jpg" class="rounded-circle avatar-sm"
                      alt="user-pic"> -->
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{notification.title}}</h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">{{notification.message}}</p>
                    <p class="mb-0" *ngIf="notification.markAsRead == false">
                      <span (click)="markAsRead(notification.id)"
                            style="color: rgb(20, 94, 230);">{{"mark as read"}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <!-- <a href="javascript:void(0);" class="text-reset notification-item">
                <div class="d-flex">
                    <div class="flex-shrink-0 avatar-sm me-3">
                        <span class="avatar-title bg-primary rounded-circle font-size-16">
                            <i class="bx bx-cart"></i>
                        </span>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                        <div class="font-size-13 text-muted">
                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span>{{
                                    'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</span></p>
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:void(0);" class="text-reset notification-item">
                <div class="d-flex">
                    <div class="flex-shrink-0 avatar-sm me-3">
                        <span class="avatar-title bg-success rounded-circle font-size-16">
                            <i class="bx bx-badge-check"></i>
                        </span>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                        <div class="font-size-13 text-muted">
                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span>{{
                                    'HEADER.NOTIFICATIONS.THIRD.TIME' |
                                    translate}}</span></p>
                        </div>
                    </div>
                </div>
            </a>

            <a href="javascript:void(0);" class="text-reset notification-item">
                <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                        <img src="assets/images/users/avatar-6.jpg" class="rounded-circle avatar-sm"
                            alt="user-pic">
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                        <div class="font-size-13 text-muted">
                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span>{{
                                    'HEADER.NOTIFICATIONS.FOUR.TIME' |
                                    translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </a> -->
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a (click)="readAll()" class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-tick-circle me-1"></i> <span>{{
              'HEADER.NOTIFICATIONS.MARK_ALL_READ' | translate}}</span>
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item right-bar-toggle me-2" (click)="toggleRightSidebar()">
              <i-feather name="settings" class="icon-lg"></i-feather>
          </button>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item bg-soft-light border-start border-end"
                id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="\assets\images\logo.jpg"
               alt="Header Avatar">
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          <span class="d-none d-xl-inline-block ms-1 fw-medium">{{user?.user?.firstName |slice:0 - 6 }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/profile"><i
            class="mdi mdi-face-profile font-size-16 align-middle me-1"></i> {{ 'MY_PROFILE' | translate }}</a>
          <!-- <a class="dropdown-item" routerLink="/account/lock-screen"><i
                  class="mdi mdi-lock font-size-16 align-middle me-1"></i> Lock screen</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
            class="mdi mdi-logout text-danger font-size-16 align-middle me-1"></i> <span
            class="align-middle text-danger" key="t-logout">{{ 'LOGOUT' | translate}}</span></a>
        </div>
      </div>

    </div>
  </div>
</header>
