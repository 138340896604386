<!-- <div class="card"> -->

<div *ngIf="applicationData" class="mt-3">
  <div class="title-applications d-flex justify-content-between">
      <span class="text-muted mb-3 pb-3 lh-1 d-block text-truncate">
       <h4> {{ 'ASSIGNED_APPLICATIONS' | translate }}
         <b>
          <span class="counter-value">({{ applicationsCount }})</span>
        </b>
        </h4>
      </span>
    <span class="d-flex align-items-center">
      <label class="form-label ms-2" style="width: 10rem">{{ 'SELECT_STATUS' | translate }}</label>
      <!-- (change)="selectStatus($event)" -->
            <select (change)="fetchData()" [(ngModel)]="filterModel.statusId" class="form-select ms-2">
              <option disabled selected value="undefined">{{ 'SELECT_STATUS' | translate }}</option>
              <option value="13">{{ 'NEW' | translate }}</option>
              <option value="3">{{ 'PENDING' | translate }}</option>
              <option value="30">{{ 'PROCESSING' | translate }}</option>
              <option value="31">{{ 'UNDER_REVIEW' | translate }}</option>
              <option value="6">{{ 'COMPLETED' | translate }}</option>
              <option value="4">{{ 'REJECTED' | translate }}</option>
              <!-- <option value="18">Verified</option>
              <option value="5">Approved</option> -->
            </select>
      <button (click)="clearFilter()"
              class="btn btn-outline-danger waves-effect waves-light ms-2"
              style="width: 6rem;">{{ 'CLEAR' | translate }}</button>
    </span>
    <!-- <div class="d-flex">
        <div class="mr-3 mb-3">
                <select (change)="fetchData()"  [(ngModel)]="filterModel.typeId" class="form-select">
                    <option disabled value="-1">{{ 'SELECT_TYPE' | translate }}</option>
                    <option [value]="4" >{{ 'INDIVIDUAL' | translate }}    </option>
                    <option [value]="1" >{{ 'GOVERNMENT' | translate }}    </option>
                    <option [value]="2" >{{ 'BUSINESS' | translate }}   </option>

                </select>
        </div>
        <div class=" mb-3">
                <select (change)="fetchData()" [(ngModel)]="filterModel.statusId" class="form-select">
                    <option disabled value="-1">{{ 'SELECT_STATUS' | translate }}</option>
                    <option [value]="13">{{ 'NEW' | translate }}</option>
                    <option [value]="9">{{ 'DRAFT' | translate }}</option>
                    <option [value]="14">{{ 'VIEWED' | translate }}</option>
                    <option [value]="11">{{ 'PENDING_CHANGES' | translate }}</option>
                </select>
        </div>
    </div> -->

  </div>
  <!-- <div class="d-flex">
      <div  *ngFor="let data of applicationData">
          <div class="card card-h-100" style="margin-right: 8px;">
              <div class="card-body">
                  <div class="row align-items-center">
                      <div >

                          <h6 class="mb-3">
                              {{data?.service?.name | translatePipe :'EN':'translation'}}
                          </h6>
                          <span class="badge rounded-pill badge-soft-primary">
                          </span>
                      </div>
                      <div >

                          <div class="align-items-center">
                              <div class="col-6">
                                  <span class="text-muted mb-3 d-block text-truncate">
                                      {{data?.service?.typeId==1 ? 'Government':data?.service?.typeId==2 ? 'Business':'Individual'}}
                                  </span>
                                  <h4 class="mb-3 badge bg-success font-size-14 fw-bold" style="margin-right: 10px;">
                                      <span [CountTo]="data?.count" class="counter-value" [from]="0" [duration]="1">
                                          {{data?.status?.status | titlecase}}
                                      </span>
                                  </h4>
                                  <button type="button" (click)="seeDetails(data.id)" class="btn btn-soft-primary btn-sm waves-effect waves-light mr-3 mb-2">Details</button>
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>
          <div *ngIf="applicationsCount == 0" class="w-100">
              <div class="mt-4 maintenance-box">
                  <div class="p-4 text-center">
                      <div class="avatar-md mx-auto">
                          <span class="avatar-title bg-soft-primary rounded-circle">
                              <i class="mdi mdi-book-remove-outline font-size-24 text-primary"></i>
                          </span>
                      </div>
                      <h5 class="font-size-15 text-uppercase mt-4">
                          {{ 'NO_NEW_APPLICATION_IN_THIS_RESPECTIVE_ACCOUNT_TYPE' | translate }} </h5>
                      <p class="text-muted mb-0">{{ 'TRY_SELECTING_OTHER_ACCOUNT_TYPES_TO_SEE_IF_YOU_HAVE_NEW_APPLICATIONS' | translate }}</p>
                  </div>
              </div>
          </div>
  </div>
   -->
  <div class="table-responsive">
    <table class="table table-hover mb-0">

      <thead>
      <tr>
        <th>{{ 'ID#' | translate }}</th>
        <!--                        <th>{{ 'USERNAME' | translate }}</th>-->
        <!--                        <th>{{ 'EMAIL' | translate }}</th>-->
        <!-- <th>{{ 'PHONE' | translate }}</th> -->
        <!-- <th>{{ 'ADDRESS' | translate }}</th> -->
        <th>{{ 'SERVICE' | translate }}</th>
        <th>{{ 'TYPE' | translate }}</th>
        <th>{{ 'NAME' | translate }}</th>
        <!--                        <th>{{ 'INTERNAL_STATUS' | translate }}</th>-->
        <th>{{ 'LAST_UPDATED' | translate }}</th>
        <th>{{ 'STATUS' | translate }}</th>
        <!-- <th>UpdatedBy</th> -->
        <!--        <th>{{ 'ACTION' | translate }}</th>-->
      </tr>
      </thead>
      <tbody>
      <tr (click)="seeDetails(news)" *ngFor="let news of applicationData    " id="table-row"
          style="vertical-align: baseline;">
        <th scope="row">{{news?.id}}</th>
        <!--                        <td title="{{news?.requester.firstName}}">{{news?.requester.firstName+ ' '+ news?.requester.lastName}}</td>-->
        <!--                        <td>{{news?.email}}</td>-->
        <!-- <td>{{news?.contactNo}}</td> -->
        <!-- <td>{{news?.houseNo}} {{news?.area}}</td> -->
        <td>{{news?.service.name | translatePipe : currentLanguage :'translation' | slice:0:20}}{{(news?.service.name | translatePipe : 'EN':'translation').length > 20 ? '...' : ''}}</td>
        <td>{{(news?.service?.typeId == 2
          ? "BUSINESS"
          : news?.service?.typeId == 1
            ? "GOVERNMENT"
            : "INDIVIDUAL") | translate
          }}</td>
        <td>{{ getName(news) }}</td>
        <!-- <td>{{news?.service.name | translate : 'AR':'translation'}}</td> -->
        <!--                        <td>-->
        <!--                            &lt;!&ndash; <button (click)="staticsModal(internalStatus, news.id)" class="success btn btn-link" title="View">-->
        <!--                                <i class="fas fa-edit"></i>-->
        <!--                             </button> &ndash;&gt;-->
        <!--                          {{(news?.internalStatus?.status | uppercase | replace:' ':'_') | translate}}-->
        <!--                        </td>-->
        <td>{{news?.updatedAt | date: 'dd.MM.yyyy'}}</td>
        <td>
          <!-- <button (click)="staticModal(externalstatus, news.id)" class="success btn btn-link" title="View">
              <i class="fas fa-edit"></i>
           </button> -->
          <!--          {{(news?.status?.status | uppercase | replace:' ':'_') | translate}}-->
          <span [ngClass]="{
                    'bg-warning': news?.status?.id == 21,
                    'bg-info': news?.status?.id == 13,
                    'bg-success': news?.status?.id == 6 || news?.status?.id == 43,
                    'bg-primary': news?.status?.id == 15,
                    'bg-danger': news?.status?.id == 11,
                    'bg-secondary': news?.status?.id == 4,
                    'bg-soft-danger': news?.status?.id == 10,
                    'bg-soft-secondary text-success': news?.status?.id == 19,
                    'bg-processing text-dark': news?.status?.id == 30,
                    'bg-pending' : news?.status?.id == 3 || news?.status?.id == 31
                  }" class="badge ms-1">
                      {{
            news?.status?.status
              | uppercase
              | replace : " " : "_"
              | translate
            }}
                    </span>
        </td>
        <!-- <td>{{news.updatedByUser}}</td> -->
        <!--        <td>-->
        <!--          &lt;!&ndash;                            <button type="button" (click)="appReview(news)" class="success btn btn-link waves-effect waves-light mb-1">&ndash;&gt;-->
        <!--          &lt;!&ndash;                                <i [title]="'Review Application'" class="mdi mdi-book-search mdi-2x mb-3"></i>&ndash;&gt;-->
        <!--          &lt;!&ndash;                            </button>&ndash;&gt;-->
        <!--          &nbsp;-->
        <!--          &lt;!&ndash;          <button type="button" (click)="seeDetails(news)" class="btn btn-sm btn-soft-primary waves-effect waves-light">&ndash;&gt;-->
        <!--          &lt;!&ndash;            {{  'REVIEW' | translate }}&ndash;&gt;-->
        <!--          &lt;!&ndash;          </button>&ndash;&gt;-->
        <!--          &lt;!&ndash; &nbsp;-->
        <!--          <button type="button" class="btn btn-soft-primary btn-sm waves-effect waves-light mb-3">Assign To</button>-->
        <!--           &nbsp;-->
        <!--          <button type="button" class="btn btn-soft-primary btn-sm waves-effect waves-light mb-3">View Details</button> &ndash;&gt;-->
        <!--          &lt;!&ndash; &nbsp; <button type="button" data-bs-toggle="modal" data-bs-target="#newsPublishModal"-->
        <!--              (click)="publishStaticModal(newsPublishModal, news)"-->
        <!--              class="btn btn-soft-primary btn-sm waves-effect waves-light">Publish</button>-->
        <!--          &nbsp; <a href="https://www.dev-site.sukoun.design/en/community/news/{{news.id}}" target="_blank" type="button" class="btn btn-soft-primary btn-sm waves-effect waves-light">Preview</a> &ndash;&gt;-->
        <!--        </td>-->
      </tr>
      </tbody>
    </table>
    <div *ngIf="!applicationData || applicationData.length==0 && loader">
      <app-bar-loader [limit]="10"></app-bar-loader>
    </div>
    <div *ngIf="!applicationData || applicationData.length==0 && !loader">
      {{ 'NO_RESULT_FOUND' | translate }}
    </div>

    <div class="row justify-content-md-between align-items-md-center mt-2"
         style="width: 100%">
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">
          <div class="dataTables_length" id="tickets-table_length"><label
            class="d-flex align-items-center">
            <label>{{ 'SHOW_ENTRIES' | translate }}</label>
            <select (change)="fetchData()" [(ngModel)]="pagination.limit" aria-controls="tickets-table"
                    class="form-control form-control-sm mx-2"
                    name="pageSize" name="tickets-table_length" style="width: 30%">
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select></label></div>
        </div>
      </div>
      <!-- Pagination -->
      <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end pagination-rounded">
          <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.pageNo"
                          [collectionSize]="totalCount" [maxSize]="5" [pageSize]="pagination.limit">
          </ngb-pagination>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</div>
<!-- </div> -->

