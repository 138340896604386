<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">

        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="commonStore.haveAccess(item.permission) || item.common">

            <!-- <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li> -->
            <li class="menu-title" *ngIf="item.isTitle"><hr></li>
  
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i-feather name="{{ item.icon }}"></i-feather>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-soft-{{item.badge.variant}} text-danger" *ngIf="item.badge">{{item.badge.text
                  | translate}}</span>
              </a>
  
              <a [routerLink]="item.link"[routerLinkActiveOptions]="{exact: true}"*ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i-feather name="{{ item.icon }}" *ngIf="item.icon"></i-feather>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-soft-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text
                  | translate}}</span>
              </a>
  
              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <ng-container  *ngFor="let subitem of item.subItems" >

                <li *ngIf="commonStore.haveAccess(subitem.permission) || item.common">
                  <a [routerLink]="subitem.link" [routerLinkActiveOptions]="{exact: true}" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                    [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                    {{ subitem.label | translate}}
                  </a>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLinkActiveOptions]="{exact: true}" [routerLink]="subSubitem.link" routerLinkActive="active"
                        class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>

      
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->