<div class="card">
  <h4 class="card-title ml-3 mr-3 mt-3">{{ assignApplication | translate }}</h4>
  <!-- <label class="ml-3 mr-3 mt-3">Assign Applications</label> -->
  <hr>
  <div *ngIf="allDetails" class="ms-3 mb-3">
    <!-- <label for="example-text-input" class="form-label">Assignee</label>
    <input disabled [ngModel]="concatenateName(allDetails.assignee)" class="form-control" type="text" id="example-text-area"> -->
    <div class="mb-3 d-flex">
      <strong>{{ 'ASSIGNEE' | translate }}:&nbsp; </strong>
      <span class="ms-2"> {{ (assignee) || ('UNASSIGNED' | translate) }}</span>
    </div>
    <div class="mb-3 d-flex">
      <strong>{{ 'FOLLOWED_BY' | translate }}:&nbsp; </strong>
      <span class="ms-2"> {{ (follower) || ('UNASSIGNED' | translate) }}</span>
    </div>
    <div class="mb-3 d-flex">
      <strong>{{ 'DEPARTMENT' | translate }}: &nbsp; </strong>
      <span> {{ 'CUSTOMER_SERVICE_DEPARTMENT' | translate }}</span>
    </div>
  </div>
  <!--  <div class=" ms-3 mt-3 mb-3">-->
  <!--      <label class="form-label">{{ 'SELECT_DEPARTMENT' | translate }}</label>-->
  <!--      <select formControlName="departmentId" (change)="onDepartment($event)" class="form-select">-->
  <!--        <option disabled value="">Select</option>-->
  <!--        <option *ngFor="let dept of departments" value="{{dept.id}}">-->
  <!--          <span>{{ currentLanguage == 'AR' ? dept?.nameAr : dept?.name }}</span>-->
  <!--                        <span dir="rtl" class="float-right">{{entity?.categoryTranslations | translate : 'AR':'title'}}</span>-->

  <!--                    <option value="2">Sharjah</option>-->
  <!--      </select>-->
  <!--  </div>-->
  <!-- <div [innerHTML]="($errorMessage | async)['typeId']" class="font-14 text-danger">  </div>
  -->
  <form [formGroup]="assignForm">
    <div class=" ml-3 mr-3 mb-3">
      <label class="form-label">{{ 'ASSIGNEE' | translate }}</label>
      <select class="form-select" formControlName="userId">
        <option disabled value="{{ undefined }}">{{ 'SELECT_USER' | translate }}</option>
        <option *ngFor="let user of getCustomerServiceUsers()" value="{{user.id}}">
          <span>{{user.firstName ? user.firstName : user.email}} {{user.lastName}} - {{user?.jobTitle}}</span>

        </option>
      </select>
      <div *ngIf="assigneeError" class="text-danger">{{ 'PLEASE_SELECT_ASSIGNEE' | translate }}</div>
    </div>

    <div class=" ml-3 mr-3 mb-3">
      <label class="form-label">{{ 'FOLLOWED_BY' | translate }}</label>
      <select class="form-select" formControlName="followedBy">
        <option disabled value="{{ undefined }}">{{ 'SELECT_USER' | translate }}</option>
        <option *ngFor="let user of getCustomerServiceUsers()" value="{{user.id}}">
          <span>{{user.firstName ? user.firstName : user.email}} {{user.lastName}} - {{user?.jobTitle}}</span>

        </option>
      </select>
      <!--      <div *ngIf="followedByError" class="text-danger">{{ 'PLEASE_SELECT_INSPECTOR' | translate }}</div>-->
    </div>
    <!-- <div [innerHTML]="($errorMessage | async)['categoryId']" class="font-14 text-danger"></div> -->

    <div class="d-flex justify-content-end mt-25 mr-3">
      <button
        (click)="onAssignClick()"
        [disabled]="!commonStore.haveAccess(permission.application_assign)"
        class="btn btn-sm btn-soft-primary waves-effect waves-light mb-2"
      >{{ 'ASSIGN' | translate }}</button>
      <button (click)="onAssignToMeClick()"
              [disabled]="!commonStore.haveAccess(permission.application_assign_to_me)"
              class="btn btn-sm btn-soft-primary waves-effect waves-light mb-2">{{ 'ASSIGN_TO_ME' | translate }}
      </button>
    </div>
  </form>
</div>
