import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import jwt_decode from 'jwt-decode';
import {ApiService} from "../api.service";
import {requests} from "../config/config";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private apiService: ApiService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let admin: any = JSON.parse(localStorage.getItem('admin') || '{}');
    const token = admin?.accessToken;

    if (token) {
      // Check if token is expired
      if (!this.isTokenExpired(token)) {
        // Token is valid, so return true
        return true;
      } else {
        // Token is expired, delete the session and navigate to login page
        this.apiService.sendRequest(requests.deleteUserSession, 'delete').subscribe(() => {
          localStorage.clear();
          this.router.navigate(['/account/login'], { queryParams: { session: 'expire' } });
        });
        return false;
      }
    }

    // Not logged in so redirect to login page with the return url
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  isTokenExpired(token: string): boolean {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) return false;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() <= new Date().valueOf();
  }
}
