import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {circle, latLng, tileLayer} from 'leaflet';
import {ChartType} from './dashboard.model';
import {requests} from 'src/app/core/config/config';
import {ApiService} from 'src/app/core/api.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 *  Dashboard Component
 */
export class DashboardComponent implements OnInit {
  activeTab = 1
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  assignedCount: number = 0;
  pendingCount: number = 0;
  title!: string;
  assignedApplications = [];
  pendingApprovalApplications = [];
  currentUser: any;
  filterModel: {
    statusId?: number,
    typeId?: number,
    assignedTo?: any,
    isPending?: boolean,
    followedBy?: number,
    dashboard?: boolean
  } = {dashboard: true}
  userName: string;
  userDepartment: number;
  dataSource!: Object;
  walletOverview!: ChartType;
  investedOverview!: ChartType;
  marketOverview!: ChartType;
  walletlineChart!: ChartType;
  tradeslineChart!: ChartType;
  investedlineChart!: ChartType;
  profitlineChart!: ChartType;
  recentActivity: any;
  News: any;
  transactionsAll: any;
  transactionsBuy: any;
  transactionsSell: any;

  // Coin News Slider
  timelineCarousel: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    navText: ["", ""],
    dots: true,
    responsive: {
      680: {
        items: 4
      },
    }
  }
  analytics: any;
  userStats: any;
  reportStats: any;
  allApplications: any;
  currentWeather: any;
  prayerTimes: any;
  /**
   * Sale Location Map
   */
  options = {
    layers: [
      tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw", {
        id: "mapbox/light-v9",
        tileSize: 512,
        zoomOffset: -1,
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      })
    ],
    zoom: 1.1,
    center: latLng(28, 1.5)
  };
  layers = [
    circle([41.9, 12.45], {
      color: "#435fe3",
      opacity: 0.5,
      weight: 10,
      fillColor: "#435fe3",
      fillOpacity: 1,
      radius: 400000,
    }),
    circle([12.05, -61.75], {
      color: "#435fe3",
      opacity: 0.5,
      weight: 10,
      fillColor: "#435fe3",
      fillOpacity: 1,
      radius: 400000,
    }),
    circle([1.3, 103.8], {
      color: "#435fe3",
      opacity: 0.5,
      weight: 10,
      fillColor: "#435fe3",
      fillOpacity: 1,
      radius: 400000,
    }),
  ];

  constructor(private apiService: ApiService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      {label: 'Dashboard'},
      {label: 'Dashboard', active: true}
    ];

    this.translate.get('DASHBOARD').subscribe((translatedValue) => {
      this.breadCrumbItems = [
        {label: translatedValue},
        {label: translatedValue, active: true},
      ];
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('DASHBOARD').subscribe((translatedValue) => {
        this.breadCrumbItems = [
          {label: translatedValue},
          {label: translatedValue, active: true},
        ];
      });
    });

    /**
     * Fetches the data
     */
    // stats call
    // this.fetchData();
    this.fetchApplications();
    this.getCurentWeather();
    this.getPrayerTimes()
  }

  setActiveTab(tab) {
    this.activeTab = tab
  }

  getCurentWeather() {
    this.apiService.sendRequest(requests.weatherApi, 'get').subscribe((res: any) => {
      this.currentWeather = res
    })
  }

  getFormatedDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return dd + '-' + mm + '-' + yyyy;
  }

  getPrayerTimes() {
    this.apiService.sendRequest(requests.prayerTime + this.getFormatedDate() + '?city=Kalba&country=Sharjah&method=8', 'get').subscribe((res: any) => {
      this.prayerTimes = res?.data;
      // this.getNextPrayerFrom()
    })

  }

  getNextPrayerFrom() {

    if (this.prayerTimes?.timings['Fajr'].split(':')[0] > new Date().getHours()) {
      console.log('fajr');
      return this.get12hours(this.prayerTimes?.timings['Fajr'].split(':')[0], this.prayerTimes?.timings['Fajr'].split(':')[1]) + ' Fajr ';
    } else if (this.prayerTimes?.timings['Dhuhr'].split(':')[0] > new Date().getHours()) {
      console.log('Dhuhr');
      return this.get12hours(this.prayerTimes?.timings['Dhuhr'].split(':')[0], this.prayerTimes?.timings['Dhuhr'].split(':')[1]) + ' Dhuhr ';
    } else if (this.prayerTimes?.timings['Asr'].split(':')[0] > new Date().getHours()) {
      return this.get12hours(this.prayerTimes?.timings['Asr'].split(':')[0], this.prayerTimes?.timings['Asr'].split(':')[1]) + ' Asr ';
    } else if (this.prayerTimes?.timings['Maghrib'].split(':')[0] > new Date().getHours()) {

      return this.get12hours(this.prayerTimes?.timings['Maghrib'].split(':')[0], this.prayerTimes?.timings['Maghrib'].split(':')[1]) + ' Maghrib ';
    } else if (this.prayerTimes?.timings['Isha'].split(':')[0] > new Date().getHours()) {
      return this.get12hours(this.prayerTimes?.timings['Isha'].split(':')[0], this.prayerTimes?.timings['Isha'].split(':')[1]) + ' Isha ';

    } else {
      return 'No Prayer Times';
    }
  }

  get12hours(h, m) {
    let dd = '';
    let hh = h;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h == 0) {
      h = 12;
    }
    // m = m < 10 ? "0" + m : m;


    /* if you want 2 digit hours:
    h = h<10?"0"+h:h; */

    var pattern = new RegExp("0?" + hh + ":" + m);

    var replacement = h + ":" + m;
    /* if you want to add seconds
    replacement += ":"+s;  */
    replacement += " " + dd;

    return replacement;
  }

  getCelciusFromFahrenheit(fahrenheit: any) {
    return (fahrenheit - 32) * 5 / 9

  }

  //  usersSum(){
  //   return this.getUserCount('INDIVIDUAL')+this.getUserCount('BUSINESS')+this.getUserCount('GOVERNMENT')
  // }

  // getDummyData(){
  //   this.walletOverview = {...walletOverview,series:[this.getUserCount('INDIVIDUAL')/this.usersSum()*100,this.getUserCount('BUSINESS')/this.usersSum()*100,this.getUserCount('GOVERNMENT')/this.usersSum()*100]};
  //   this.investedOverview = investedOverview;
  //   this.marketOverview = marketOverview;
  //   this.walletlineChart = walletlineChart;
  //   this.tradeslineChart = tradeslineChart;
  //   this.investedlineChart = investedlineChart;
  //   this.profitlineChart = profitlineChart;
  //   this.recentActivity = recentActivity;
  //   this.News = News;
  //   this.transactionsAll = transactionsAll;
  //   this.transactionsBuy = transactionsBuy;
  //   this.transactionsSell = transactionsSell;

  fetchApplications() {
    let user = JSON.parse(localStorage.getItem('admin'));
    this.currentUser = user.user?.id;
    this.userDepartment = +user?.user?.department?.id;
    this.userName = user.user?.firstName + " " + user.user?.lastName;

    delete this.filterModel['isPending'];
    delete this.filterModel['followedBy'];
    this.filterModel.assignedTo = user?.user?.id;
    this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((res: any) => {
      this.assignedApplications = res?.data.rows;
      this.assignedCount = res?.data.count;
      delete this.filterModel['assignedTo'];
      delete this.filterModel['isPending'];
      this.filterModel.followedBy = user?.user?.id;
      this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((_res: any) => {
        this.assignedApplications = [...this.assignedApplications, ..._res?.data?.rows]
        this.assignedCount += _res?.data?.count;
        delete this.filterModel['assignedTo'];
        delete this.filterModel['followedBy'];
        this.filterModel.isPending = true;
        this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((_res: any) => {
          this.assignedApplications = [...this.assignedApplications, ..._res?.data?.rows]
          this.assignedCount += _res?.data?.count;
        })
      })
    });
  }

  // getUserCount(userType:any){
  //   if(this.userStats){

  //     return  this.userStats?.usersCountByUserType.find(x=>x.userType?.type==userType)?.count ?? ''
  //   }
  //   return ''
  // }

  // }
  /**
   * Fetches the data
   */
  private fetchData() {

    this.apiService.sendRequest(requests.serviceStats, 'get').subscribe((res: any) => {
      this.analytics = res?.data
    })
    // this.apiService.sendRequest(requests.userStats, 'get').subscribe((res:any) => {
    //   this.userStats = res?.data;
    //   this.getDummyData()
    // })
    this.apiService.sendRequest(requests.instantReportsStats, 'get').subscribe((res: any) => {
      this.reportStats = res?.data
    })
    // this.apiService.sendRequest(requests.applicationStats, 'get').subscribe((res:any) => {
    //   this.applicationsStats = res?.data
    //   this.applicationStatus = this.applicationsStats?.applicationsCountByStatus.filter(item => item.status.status !== "Draft").map(item => ({status: item.status.status, count: item.count}));
    // })

  }


}
