import {Injectable} from '@angular/core';
// import { UserModel } from '../shared/models/user';
// import { observable, action, computed, autorun} from 'mobx';
import {SnakbarModel} from './../models/snakbar.model'
import {Subject} from "rxjs";
// import { cloneDeep } from 'lodash';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonStore {
  loader: boolean = false;
  //   @observable userModisOpenel: UserModel;
  // alertArray: Array<SnakbarModel> = [{message:'testing alert',case:'error',isOpen:true}];
  globalAlert: SnakbarModel;
  alertArray: Array<SnakbarModel> = [];
  redirectUrl: any;
  fetchedApplication = new Subject<boolean>();

  constructor() {
    if (window.localStorage['redirectUrl']) {
      let redUrl = JSON.parse(localStorage["redirectUrl"]);
      this.redirectUrl = Object.assign({}, redUrl);
    }

  }

  get loaderState() {
    return this.loader;
  }

  loaderStart() {
    if (this.loader == true) {
      this.loader = false;
    }
    setTimeout(() => {
      this.loader = true;
    }, 1000);
  }

  loaderEnd() {
    setTimeout(() => {
      if (this.loader)
        this.loader = false;
    }, 1000);
  }

  notifier(notification) {
    this.globalAlert = new SnakbarModel();
    this.globalAlert.id = Math.random().toString();
    this.globalAlert.isOpen = true;
    this.globalAlert.message = notification.message;
    this.globalAlert.case = notification.action;
    this.globalAlert.local = notification.local ? notification.local : false;
    if (!this.alertArray.some(x => x.message == notification.message)) {
      this.alertArray.push(this.globalAlert);
    }
    setTimeout(() => {
      if (this.alertArray && this.alertArray.length > 0) {
        this.alertArray.splice(0, 1)
      }
    }, 5000);
  }

  closeNotifier(alert) {
    let index = this.alertArray.findIndex(x => x.id == alert.id);
    if (index > -1) {
      this.alertArray.splice(index, 1);
    }
  }


  clearStore() {
    window.localStorage.clear();
    this.redirectUrl = null;
    $('.modal').modal('hide');
  }

  resetRedirectUrl() {
    this.redirectUrl = null;
    window.localStorage.removeItem('redirectUrl');
  }

  setRedirectUrl(url: any) {
    this.redirectUrl = url;
    window.localStorage.setItem('redirectUrl', JSON.stringify(this.redirectUrl));

  }

  haveAccess(keyToCheck: string): boolean {
    // console.log('key check', keyToCheck);
    let admin = JSON.parse(localStorage.getItem('admin'));
    if (admin && admin.user.role == 'admin') {
      return true;
    } else {

      if (admin && admin.permissions && admin.permissions.length > 0) {
        return admin.permissions.some(userRight => userRight.permission == keyToCheck);
      } else {
        console.log("not accessable")
        return false;
      }
    }
  }


}
