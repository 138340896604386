import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-loader',
  templateUrl: './bar-loader.component.html',
  styleUrls: ['./bar-loader.component.scss']
})
export class BarLoaderComponent implements OnInit {
@Input() limit:number=1
loader=`<div class="placeholder-glow w-100" *ngFor="let c of limt">
<span class="placeholder placeholder-lg col-12"></span>
</div>`
  constructor() { }

  ngOnInit(): void {
    
  }

  getLoader(){
    return this.loader.repeat(this.limit)
  }

}
