import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
// Initialize Firebase
      initializeApp(firebaseConfig);
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          sessionStorage.setItem('authUser', JSON.stringify(user));
        } else {
          sessionStorage.removeItem('authUser');
        }
      });
    }
  }

  registerUser(email, password) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          resolve(userCredential.user);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  }

  loginUser(email, password) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          resolve(userCredential.user);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  }

  forgetPassword(email) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email, { url: window.location.protocol + '//' + window.location.host + '/login' })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  }

  setLoggeedInUser(user) {
    sessionStorage.setItem('authUser', JSON.stringify(user));
  }

  getAuthenticatedUser() {
    if (!sessionStorage.getItem('authUser')) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem('authUser')!);
  }

  _handleError(error) {
    const errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
