import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {requests} from 'src/app/core/config/config';
import {ApiService} from 'src/app/core/services/api.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'application-widget',
  templateUrl: './application-widget.component.html',
  styleUrls: ['./application-widget.component.scss']
})
export class ApplicationWidgetComponent implements OnInit {
  @Input() applicationData: any = [];
  @Input() applicationsCount: number;
  userId: number = -1;
  filterModel: {
    followedBy?: number,
    statusId?: number,
    typeId?: number,
    assignedTo?: any,
    isPending?: boolean,
    limit?: number,
    dashboard?: boolean
  } = {dashboard: true}
  loader: boolean;
  pagination: {
    pageNo: number,
    limit: number,
    offset: number,
    typeId?: number,
    statusId?: number,
    dashboard?: boolean
  } = {
    pageNo: 1,
    limit: 10,
    offset: 0,
    dashboard: true
  }
  totalCount: any;
  currentLanguage: string = "EN";

  constructor(private apiService: ApiService,
              private router: Router,
              private translate: TranslateService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang.toUpperCase();
    this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang.toUpperCase();
    });
    // this.activatedRoute.paramMap.subscribe((params: ParamMap | any) => {
    //   this.userId = + params.get('id');
    // });
    // this.filterModel.statusId = null;
    // this.filterModel.typeId = null;
  }

  fetchData() {
    let user = JSON.parse(localStorage.getItem('admin'));
    if ('statusId' in this.filterModel) {
      delete this.filterModel['dashboard'];
    }
    this.filterModel.limit = this.pagination.limit;
    this.filterModel.assignedTo = user?.user?.id;
    delete this.filterModel['isPending'];
    delete this.filterModel['followedBy'];
    this.filterModel.assignedTo = user?.user?.id;
    this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((res: any) => {
      this.applicationData = res?.data.rows;
      this.applicationsCount = res?.data.count;
      delete this.filterModel['assignedTo'];
      delete this.filterModel['isPending'];
      this.filterModel.followedBy = user?.user?.id;
      this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((_res: any) => {
        this.applicationData = [...this.applicationData, ..._res?.data?.rows]
        this.applicationsCount += _res?.data?.count;
        delete this.filterModel['assignedTo'];
        delete this.filterModel['followedBy'];
        this.filterModel.isPending = true;
        this.apiService.sendRequest(requests.getAllApplications, 'get', this.filterModel).subscribe((_res: any) => {
          this.applicationData = [...this.applicationData, ..._res?.data?.rows]
          this.applicationsCount += _res?.data?.count;
        })
      })
    });
  }

  seeDetails(app: any) {
    this.router.navigate(['/applications/details/' + app?.id])
  }

  clearFilter(data?: any) {
    delete this.filterModel['statusId'];
    this.filterModel.dashboard = true;
    this.filterModel.limit = 10;
    this.fetchData();
  }

  clear() {
    // this.selectType=null;
    this.pagination = {pageNo: 1, limit: 10, offset: 0}
    this.fetchData()
  }

  changeType() {
    this.pagination.typeId = this.filterModel.typeId;
    this.fetchData()
  }

  appReview(news: any) {
    this.router.navigate(['/applications/review/' + news.id])
  }

  getName(news) {
    const requester = news?.requester;
    if (requester?.company) {
      if (requester?.company?.govtEntity) {
        if (this.currentLanguage == 'EN') {
          return this.truncateString(requester?.company?.govtEntity?.nameEn, 20);
        } else if (this.currentLanguage == 'AR') {
          return this.truncateString(requester?.company?.govtEntity?.nameAr, 20);
        }
      }
      if (requester?.company?.companyDetail) {
        if (this.currentLanguage == 'EN') {
          return this.truncateString(requester?.company?.companyDetail?.tradeNameEnglish, 20);
        } else if (this.currentLanguage == 'AR') {
          return this.truncateString(requester?.company?.companyDetail?.tradeNameArabic, 20);
        }
      }
    } else if (requester?.firstName || requester.lastName) {
      const fullName = (requester?.firstName ? requester.firstName : "") + " " + (requester?.lastName ? requester.lastName : "");
      return this.truncateString(fullName, 20);
    }
    return this.truncateString(requester?.email, 20);
  }

  truncateString(str, maxLength) {
    return str.length > maxLength ? str.slice(0, maxLength).trim() + '...' : str;
  }

  onPageChange(event: any) {
    this.pagination.pageNo = event;
    // let page = this.pagination.pageNo == 1 ? 0 : this.pagination.pageNo - 1;
    this.pagination.offset = event == 1 ? 0 : (event - 1) * this.pagination.limit;
    this.fetchData()
  }

}
