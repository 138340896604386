<!-- start page title -->
<!--<app-pagetitle title="{{ 'DASBOARDH' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>-->
<!-- end page title -->
<!-- <iframe width="600" height="800" src="https://datastudio.google.com/embed/reporting/dcceddb9-faee-426b-8182-65454dd6f326/page/1M" frameborder="0" style="border:0" allowfullscreen></iframe>-->
<div class="d-flex justify-content-between align-items-center">
  <h3 class="mb-3"> {{ 'HELLO' | translate }} {{userName}}</h3>
  <div class="d-flex align-items-center">
    <h6> Next Prayer Time {{getNextPrayerFrom()}} </h6>&nbsp;&nbsp;
    <h6><img
      src="{{ currentWeather?.weather[0]?.icon? 'http://openweathermap.org/img/w/'+currentWeather?.weather[0]?.icon+'.png' : ''}}">
      Weather Today: Kalba {{currentWeather?.main?.temp}} C</h6>
  </div>
</div>

<div *ngIf="userDepartment !== 14" class="col-xl-12">
  <div class="card">
    <div class="card-body">
      <!-- Nav tabs -->
      <ul #nav="ngbNav" [activeId]="1" class="nav-tabs" ngbNav>
        <li (click)="setActiveTab(1)" [ngbNavItem]="1">
          <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block">{{ 'ASSIGNED_APPLICATIONS' | translate }}
              <span class="badge bg-soft-primary text-dark ms-1">{{assignedCount}}</span>
            </span>
          </a>
        </li>
        <!--  <li [ngbNavItem]="2" (click)="setActiveTab(2)">
            <a ngbNavLink>
              <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
              <span class="d-none d-sm-block">{{ 'PENDING_APPROVAL' | translate }}
                <span class="badge bg-soft-warning text-dark ms-1">{{pendingCount}}</span>
               </span>
            </a>
            <ng-template ngbNavContent>
              <pending-approval-appliations [applications]="pendingApprovalApplications"></pending-approval-appliations>
            </ng-template>
          </li> -->
        <!-- FOR FUTURE USE -->
        <!--        <li [ngbNavItem]="3">-->
        <!--          <a ngbNavLink>-->
        <!--            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>-->
        <!--            <span class="d-none d-sm-block">{{ 'APP_STATS' | translate }}</span>-->
        <!--          </a>-->
        <!--          <ng-template ngbNavContent>-->
        <!--             <app-stats></app-stats>-->
        <!--          </ng-template>-->
        <!--        </li>-->
        <!--        <li [ngbNavItem]="4">-->
        <!--          <a ngbNavLink>-->
        <!--            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>-->
        <!--            <span class="d-none d-sm-block">{{ 'USER_STATS' | translate }}</span>-->
        <!--          </a>-->
        <!--          <ng-template ngbNavContent>-->
        <!--             <app-users-stats></app-users-stats>-->
        <!--          </ng-template>-->
        <!--        </li>-->
      </ul>
      <ng-template [ngTemplateOutlet]="TabContent"></ng-template>

      <ng-template #TabContent>
        <application-widget *ngIf="activeTab==1" [applicationData]="assignedApplications"
                            [applicationsCount]="assignedCount"></application-widget>
        <pending-approval-appliations *ngIf="activeTab==2" [applicationsCount]="pendingCount"
                                      [applications]="pendingApprovalApplications"></pending-approval-appliations>

      </ng-template>
      <!-- <div [ngbNavOutlet]="nav" class="text-muted"></div> -->
    </div><!-- end card-body -->
  </div><!-- end card -->
</div><!-- end col -->
<app-media-team-dashboard *ngIf="userDepartment === 14"></app-media-team-dashboard>
