import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage } from './chat.model';
// import { , chatMessagesData } from './data';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/api.service';
import { requests } from 'src/app/core/config/config';
@Component({
  selector: 'app-comments',
  templateUrl: './app-comments.component.html',
  styleUrls: ['./app-comments.component.scss']
})
export class AppCommentsComponent implements OnInit {
  @Input() id: any = null
  // chatData!: ChatUser[];
  chatMessagesData!: ChatMessage[];
  username: string = 'Jennie Sherlock';
  usermessage!: string;
  formData!: UntypedFormGroup;
  chatSubmit?: boolean;
  profile: string = 'assets/images/users/avatar-2.jpg';
  user: any;
  @Input() allowSendingComment: boolean;
  constructor(private formBuilder: FormBuilder,
    public apiService: ApiService) { }

  ngOnInit(): void {
    // Validation
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    this.chatSubmit = false;
    this._fetchData();
    this.user = JSON.parse(localStorage.getItem('admin')).user
    // mesage
  }
  messageSave() {
    const message = this.formData.get('message')!.value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatMessagesData.push({
        align: 'right',
        name: this.user.username,
        // profile: 'assets/images/users/avatar-4.jpg',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes(),
      });
      this.onListScroll();
      this.apiService.sendRequest(requests.getApplicationFeedback, 'post', {
        "applicationId": this.id,
        "feedback": message,
        "isInternal": true
      }).subscribe((res: any) => {
        this._fetchData();
      })
      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null,
      });
    }
    this.chatSubmit = true;
  }
  get form() {
    return this.formData.controls;
  }
  onListScroll() { }

  private _fetchData() {
    // this.chatData = chatData;
    // this.chatMessagesData = chatMessagesData;
    this.apiService.sendRequest(requests.getApplicationFeedback, 'get', { applicationId: this.id, limit: 10, pageNo: 1 }).subscribe((res: any) => {
      console.log("log comments", res);
      this.chatMessagesData = this.convertChatToViewModal(res.data.rows).sort(function (a, b) {
        var key1 = a.time;
        var key2 = b.time;

        if (key1 < key2) {
          return -1;
        } else if (key1 == key2) {
          return 0;
        } else {
          return 1;
        }
      })
    })
  }

  convertChatToViewModal(commentsArray: Array<any>) {
    let user = JSON.parse(localStorage.getItem('admin'));
    if (commentsArray.length) {
      return commentsArray.map(comment => {
        return {
          ...(comment.createdBy == user?.user.id ? { align: 'right' } : null),
          name: comment?.createdByUser.username,
          message: comment?.feedback,
          time: comment?.createdAt,
          profile: 'assets/images/users/avatar-4.jpg'
        }
      })
    } else {
      return []
    }
  }

}
