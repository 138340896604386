import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

import {CookieService} from 'ngx-cookie-service';
import {EventService} from '../../core/services/event.service';
import {LanguageService} from '../../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../core/services/auth.service';
import {AuthfakeauthenticationService} from '../../core/services/authfake.service';
import {requests} from 'src/app/core/config/config';
import {LAYOUT_MODE} from "../layouts.model";
import {ApiService} from 'src/app/core/api.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {

  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  pagination: { limit: number, offset: number } = {limit: 100, offset: 0}
  notifications: any;
  notificationDetails: any;
  user: any;
  dir: any;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private eventService: EventService,
    private apiService: ApiService,
  ) {
  }

  /**
   * Language Listing
   */
  listLang = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
    {text: 'Arabic', flag: 'assets/images/flags/us.jpg', lang: 'ar'},
  ];

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  layoutMode!: string;

  ngOnInit(): void {
    this.getNotification();
    this.user = JSON.parse(localStorage.getItem('admin'))
    this.layoutMode = LAYOUT_MODE;

    this.element = document.documentElement;
    // Get current language using TranslateService
    this.getCurrentLang();
    this.getCurrentDir();
    // // Cookies wise Language set
    // this.cookieValue = this._cookiesService.get('lang');
    // const selectedLang = this.listLang.find(lang => lang.lang === this.cookieValue);
    // if (selectedLang) {
    //   this.countryName = selectedLang.text;
    //   this.flagvalue = selectedLang.flag;
    // } else {
    //   this.flagvalue = "assets/images/flags/us.jpg";
    //   this.countryName = "English";
    // }
  }

  goToPage(notification) {
    if (notification.notificationTypeId == 1) {
      return '/business/details/' + notification?.dataId
    }
    if (notification.notificationTypeId == 2) {
      return '/instant-reports/details/' + notification?.dataId
    }
    if (notification.notificationTypeId == 3) {
      return '/gov/details/' + notification?.dataId
    }
    if (notification.notificationTypeId == 4) {
      return '/applications/details/' + notification?.dataId
    }
    if (notification.notificationTypeId == 5) {
      return '/services/edit/' + notification?.dataId
    }
    // if(notification.notificationTypeId==6){
    //   return '/instant-reports/details/'+notification?.dataId
    // }
    if (notification.notificationTypeId == 7) {
      return '/instant-reports/details/' + notification?.dataId
    }
    if (notification.notificationTypeId == 8) {
      return '/complaints/details/' + notification?.dataId
    }
    return '/'
  }


  /**
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this.eventService.broadcast('changeLanguage', text);
  }

  getCurrentLang(): void {
    const storedLang = this._cookiesService.get('lang');
    const currentLang = storedLang || this.translate.getDefaultLang();
    this.translate.use(currentLang); // Update TranslateService with the stored language

    const selectedLang = this.listLang.find(lang => lang.lang === currentLang);
    if (selectedLang) {
      this.countryName = selectedLang.text;
      this.flagvalue = selectedLang.flag;
    } else {
      this.flagvalue = "assets/images/flags/us.jpg";
      this.countryName = "English";
    }
  }

  getCurrentDir() {
    this.dir = this._cookiesService.get('dir');
    if (this.dir) {
      document.body.setAttribute("dir", this.dir);
    }
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.layoutMode = mode;
    this.mode = mode;
    this.eventService.broadcast('changeMode', mode);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.router.navigate(['/account/login']);
  }

  readAll() {
    this.apiService.sendRequest(requests.markAllAsRead, 'get').subscribe(res => {
      this.getNotification()
    })
  }

  getNotification() {
    this.apiService.sendRequest(requests.getAllNotifications, 'get', this.pagination).subscribe((res: any) => {
      this.notifications = res?.data;
      this.notificationDetails = this.notifications?.notifications;
      console.log(this.notificationDetails)
    })
  }

  markAsRead(id: any) {
    this.apiService.sendRequest(requests.markAsRead + id, 'get').subscribe((res) => {
      console.log(res)
      this.getNotification()
    })
  }

}
