import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {CommonStore} from '../core/services/common.store';
import {EventService} from '../core/services/event.service';
import {
  LAYOUT_HORIZONTAL,
  LAYOUT_MODE,
  LAYOUT_POSITION,
  LAYOUT_VERTICAL,
  LAYOUT_WIDTH,
  SIDEBAR_COLOR,
  SIDEBAR_SIZE,
  TOPBAR
} from './layouts.model';
import {environment} from "../../environments/environment";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {requests} from "../core/config/config";
import {ApiService} from "../core/api.service";
import {ToastrService} from "ngx-toastr";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {CookieService} from "ngx-cookie-service";
import {Subscription} from "rxjs";
import {NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

/**
 * Layout Component
 */
export class LayoutComponent implements OnInit, OnDestroy {

  // layout related config
  layoutType!: string;
  layoutMode!: string;
  layoutwidth!: string;
  layoutposition!: string;
  topbar!: string;
  sidebarcolor!: string;
  sidebarsize!: string;
  language: any;
  message: any = null;
  activeToasts = new Map<string, any>();
  title: string = "Smart Kalba Admin Panel";
  isNavigating: boolean = false;
  routerSub?: Subscription;

  constructor(
    private eventService: EventService,
    public commonStore: CommonStore,
    private apiService: ApiService,
    private toastr: ToastrService,
    private titleService: Title,
    private translate: TranslateService,
    private cookieService: CookieService,
    private router: Router) {
  }

  ngOnInit() {
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isNavigating = true;
      }
    });
    // this.updateTranslation();
    //
    // this.translate.onLangChange.subscribe(() => {
    //   this.updateTranslation();
    // });
    this.layoutMode = LAYOUT_MODE;
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.layoutposition = LAYOUT_POSITION;
    this.sidebarcolor = SIDEBAR_COLOR;
    this.sidebarsize = SIDEBAR_SIZE;
    this.topbar = TOPBAR;

    this.LayoutMode(this.layoutMode);
    this.LayoutWidth(this.layoutwidth);
    this.LayoutPosition(this.layoutposition);
    this.Topbar(this.topbar);
    this.SidebarColor(this.sidebarcolor);
    this.SidebarSize(this.sidebarsize);
    if (!(localStorage.getItem("firebaseToken"))) {
      this.requestPermission();
    }
    this.listen();

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });

    this.eventService.subscribe('changeMode', (mode) => {
      this.layoutMode = mode;
      this.LayoutMode(this.layoutMode);
    });

    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    this.eventService.subscribe('changePosition', (position) => {
      this.layoutposition = position;
      this.LayoutPosition(this.layoutposition);
    });

    this.eventService.subscribe('changeTopbar', (topbar) => {
      this.topbar = topbar;
      this.Topbar(this.topbar);
    });

    this.eventService.subscribe('changeSidebarColor', (sidebarcolor) => {
      this.sidebarcolor = sidebarcolor;
      this.SidebarColor(this.sidebarcolor);
    });

    this.eventService.subscribe('changeSidebarSize', (sidebarsize) => {
      this.sidebarsize = sidebarsize;
      this.SidebarSize(this.sidebarsize);
    });

    this.eventService.subscribe('changeLanguage', (language) => {
      this.language = language;
      this.languageEffect(this.language);
    });

  }

  @HostListener('window:beforeunload', ['$event'])
  onReload($event: any) {
    if (!this.isNavigating) {
      localStorage.removeItem('serviceFilter');
      localStorage.removeItem('applicationFilter');
      localStorage.removeItem('serviceCatFilter');
      localStorage.removeItem('customerFilter');
      localStorage.removeItem('userFilter');
      localStorage.removeItem('complaintFilter');
      localStorage.removeItem('instantReportFilter');
      localStorage.removeItem('expressionFilter');
    }
  }

  ngAfterViewInit() {
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  /**
   * Layout Mode Set
   */
  LayoutMode(mode: string) {
    switch (mode) {
      case "light":
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-topbar", "light");
        document.body.setAttribute("data-sidebar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        document.body.setAttribute("data-layout-mode", "dark");
        document.body.setAttribute("data-topbar", "dark");
        break;
      default:
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-topbar", "light");
        break;
    }

  }

  /**
   * Layout Width Set
   */
  LayoutWidth(width: string) {
    switch (width) {
      case "light":
        document.body.setAttribute("data-layout-size", "fluid");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        break;
      default:
        document.body.setAttribute("data-layout-size", "light");
        break;
    }
  }

  /**
   * Layout Position Set
   */
  LayoutPosition(position: string) {
    if (position === 'fixed') {
      document.body.setAttribute("data-layout-scrollable", "false");
    } else {
      document.body.setAttribute("data-layout-scrollable", "true");
    }
  }

  /**
   * Layout Sidebar Color Set
   */
  SidebarColor(color: string) {
    switch (color) {
      case "light":
        document.body.setAttribute('data-sidebar', 'light');
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        break;
      case "brand":
        document.body.setAttribute("data-sidebar", "brand");
        break;
      default:
        document.body.setAttribute("data-sidebar", "light");
        break;
    }
  }

  /**
   * Layout Topbar Set
   */
  Topbar(topbar: string) {
    switch (topbar) {
      case "light":
        document.body.setAttribute("data-topbar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-topbar", "dark");
        break;
      default:
        document.body.setAttribute("data-topbar", "light");
        break;
    }
  }

  /**
   * Layout Sidebar Size Set
   */
  SidebarSize(size: string) {
    switch (size) {
      case "default":
        document.body.setAttribute('data-sidebar-size', 'lg');
        break;
      case "compact":
        document.body.setAttribute('data-sidebar-size', 'md');
        break;
      case "small":
        document.body.setAttribute('data-sidebar-size', 'sm');
        break;
      default:
        document.body.setAttribute('data-sidebar-size', 'lg');
        break;
    }
  }

  languageEffect(language) {
    switch (language) {
      case "default":
        document.body.setAttribute('dir', 'ltr');
        break;
      case "Arabic":
        document.body.setAttribute('dir', 'rtl');
        // Remove existing cookies if they exist
        ['lang', 'dir'].forEach((key) => {
          if (this.cookieService.check(key)) {
            this.cookieService.delete(key);
          }
        });
        // Set new cookies
        this.cookieService.set('lang', 'ar', 30);
        this.cookieService.set('dir', 'rtl', 30);
        break;
      case "English":
        document.body.setAttribute('dir', 'ltr');
        // Remove existing cookies if they exist
        ['lang', 'dir'].forEach((key) => {
          if (this.cookieService.check(key)) {
            this.cookieService.delete(key);
          }
        });
        // Set new cookies
        this.cookieService.set('lang', 'en', 30);
        this.cookieService.set('dir', 'ltr', 30);
        break;
    }
  }

  requestPermission() {
    const messaging = getMessaging();
    let self = this;
    getToken(messaging,
      {vapidKey: environment.firebaseConfig.vapidKey}).then(
      (firebaseToken) => {
        if (firebaseToken) {
          console.log("Firebase Token", firebaseToken);
          localStorage.setItem("firebaseToken", firebaseToken);
          let sessionToken = JSON.parse(localStorage.getItem("admin")).accessToken;
          let loginVia = navigator.userAgent;
          self.apiService.sendRequest(requests.createUserSession, 'post', {
            sessionToken: sessionToken,
            loginVia: 'web' || loginVia,
            firebaseToken: firebaseToken
          }).subscribe()
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
      console.log('An error occurred while retrieving firebase token. ', err);
    });
  }

  listen() {
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      console.log('Received foreground notification ', payload);
      const notificationBody = payload.notification.body;
      const notificationTitle = payload.notification.title;

      // Create a unique key for this notification
      const key = `${notificationTitle}-${notificationBody}`;

      // If an identical notification is already being displayed, return early
      if (this.activeToasts.has(key)) {
        return;
      }

      // Show the new toast
      const toast = this.toastr.success(notificationBody, notificationTitle, {
        positionClass: 'toast-top-right',
        timeOut: 5000
      });

      // Remember this toast so we can prevent identical ones from being shown
      this.activeToasts.set(key, toast);

      // After 5 seconds (timeOut value), remove the toast from active toasts
      setTimeout(() => {
        this.activeToasts.delete(key);
      }, 5000);
    });
  }

  // setTitle(newTitle: string) {
  //   this.titleService.setTitle(newTitle);
  // }
  //
  // private updateTranslation(): void {
  //   this.translate.get([
  //     "SMART_KALBA_ADMIN_PANEL"
  //   ]).subscribe(translations => {
  //     this.title = translations["SMART_KALBA_ADMIN_PANEL"];
  //     this.setTitle(this.title);
  //   });
  // }
  ngOnDestroy() {
    this.routerSub?.unsubscribe();  // always unsubscribe your subscriptions to prevent memory leaks
  }
}
