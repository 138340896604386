import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { GovContainerComponent } from './government/gov-container/gov-container.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'gov',
    loadChildren:()=>import('./government/government.module').then(m=> m.GovernmentModule)
  },
  {
    path: 'business',
    loadChildren:()=>import('./business/business.module').then(m=> m.BusinessModule)
  },
  {
    path: 'ind',
    loadChildren:()=>import('./individuals/individuals.module').then(m=> m.IndividualsModule)
  },
  {
    path: 'cover-images',
    loadChildren:()=>import('./community/cover-images/cover-images.module').then(m=> m.CoverImagesModule)
  },
  {
    path: 'news',
    loadChildren:()=>import('./community/news/news.module').then(m=> m.NewsModule)
  },
  {
    path: 'holidays',
    loadChildren:()=>import('./community/holidays/holidays.module').then(m=> m.HolidaysModule)
  },
  {
    path: 'events',
    loadChildren:()=>import('./community/events/events.module').then(m=> m.EventsModule)
  },
  {
    path: 'destinations',
    loadChildren:()=>import('./community/destinations/destinations.module').then(m=> m.DestinationsModule)
  },
  {
    path: 'services',
    loadChildren:()=>import('./services/services.module').then(m=> m.ServicesModule)
  },
  {
    path: 'instant-reports',
    loadChildren:()=>import('./instant-reports/instant-reports.module').then(m=> m.InstantReportsModule)
  },
  {
    path: 'complaints',
    loadChildren:()=>import('./compaint-and-suggestions/compaint-and-suggestions.module').then(m=> m.CompaintAndSuggestionsModule)
  },
  {
    path: 'settings',
    loadChildren:()=>import('./settings/settings.module').then(m=> m.SettingsModule)
  },
  {
    path: 'admins',
    loadChildren:()=>import('./administration/administration.module').then(m=> m.AdministrationModule)
  },
  {
    path: 'applications',
    loadChildren:()=>import('./applications/applications.module').then(m=> m.ApplicationsModule)
  },
  {
    path: 'about-kalba',
    loadChildren:()=>import('./about-kalba/about-kalba.module').then(m=> m.AboutKalbaModule)
  },
  {
    path: 'locations',
    loadChildren:()=>import('./locations/locations.module').then(m=> m.LocationsModule)
  },
  {
    path: 'utilities',
    loadChildren:()=>import('./utilities/utilities.module').then(m=> m.UtilitiesModule)
  },
  {
    path: 'expressions',
    loadChildren:()=>import('./expressions/expressions.module').then(m=> m.ExpressionsModule)
  },
  {
    path: 'profile',
    loadChildren:()=>import('./profile/profile.module').then(m=> m.ProfileModule)
  },
  {
    path: 'customers',
    loadChildren:()=>import('./customer/customer.module').then(m=> m.CustomerModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
