import { Component, OnInit } from '@angular/core';
import { ChartType } from '../dashboard.model';
import { walletOverview, investedOverview, marketOverview, walletlineChart, tradeslineChart, investedlineChart, profitlineChart, recentActivity, News, transactionsAll, transactionsBuy, transactionsSell } from '../data';
import { ApiService } from 'src/app/core/api.service';
import { requests } from 'src/app/core/config/config';

@Component({
  selector: 'app-users-stats',
  templateUrl: './users-stats.component.html',
  styleUrls: ['./users-stats.component.scss']
})
export class UsersStatsComponent implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  title!: string;
  dataSource!: Object;
  walletOverview!: ChartType;
  investedOverview!: ChartType;
  marketOverview!: ChartType;
  walletlineChart!: ChartType;
  tradeslineChart!: ChartType;
  investedlineChart!: ChartType;
  profitlineChart!: ChartType;
  recentActivity: any;
  News: any;
  transactionsAll: any;
  transactionsBuy: any;
  transactionsSell: any;

  userStats: any;

  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    this.apiService.sendRequest(requests.userStats, 'get').subscribe((res:any) => {
      this.userStats = res?.data;
      this.getDummyData()
    })
  }

  getUserCount(userType:any){
    if(this.userStats){

      return  this.userStats?.usersCountByUserType.find(x=>x.userType?.type==userType)?.count ?? ''
    }
    return ''
  }
  usersSum(){
    return this.getUserCount('INDIVIDUAL')+this.getUserCount('BUSINESS')+this.getUserCount('GOVERNMENT')
  }
  getDummyData(){
    this.walletOverview = {...walletOverview,series:[this.getUserCount('INDIVIDUAL')/this.usersSum()*100,this.getUserCount('BUSINESS')/this.usersSum()*100,this.getUserCount('GOVERNMENT')/this.usersSum()*100]};
    this.investedOverview = investedOverview;
    this.marketOverview = marketOverview;
    this.walletlineChart = walletlineChart;
    this.tradeslineChart = tradeslineChart;
    this.investedlineChart = investedlineChart;
    this.profitlineChart = profitlineChart;
    this.recentActivity = recentActivity;
    this.News = News;
    this.transactionsAll = transactionsAll;
    this.transactionsBuy = transactionsBuy;
    this.transactionsSell = transactionsSell;

  }

}
