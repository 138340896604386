import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CountToModule} from 'angular-count-to';
// import { AddNewsComponent } from './community/news/add-news/add-news.component';
import {NgbDropdownModule, NgbModule, NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
// import { NgApexchartsModule } from 'ng-apexcharts';
import {SimplebarAngularModule} from 'simplebar-angular';
// import { CarouselModule } from 'ngx-owl-carousel-o';
import {FeatherModule} from 'angular-feather';
import {allIcons} from 'angular-feather/icons';
import {LightboxModule} from 'ngx-lightbox';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

import {SharedModule} from '../shared/shared.module';
import {WidgetModule} from '../shared/widget/widget.module';

import {PagesRoutingModule} from './pages-routing.module';

import {DashboardComponent} from './dashboard/dashboard.component';
import {ApplicationWidgetComponent} from './dashboard/application-widget/application-widget.component';
import {FormsModule} from '@angular/forms';
import {LayoutsModule} from '../layouts/layouts.module';
import {
  PendingApprovalAppliationsComponent
} from './dashboard/pending-approval-appliations/pending-approval-appliations.component';
import {StatsComponent} from './dashboard/stats/stats.component';
import {UsersStatsComponent} from './dashboard/users-stats/users-stats.component';
import {LoaderModule} from "../modules/loader/loader.module";
import {MediaTeamDashboardComponent} from './dashboard/media-team-dashboard/media-team-dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ApplicationWidgetComponent,
    PendingApprovalAppliationsComponent,
    StatsComponent,
    UsersStatsComponent,
    MediaTeamDashboardComponent,

    // AddNewsComponent
  ],
  imports: [
    CommonModule,
    LayoutsModule,
    WidgetModule,
    CountToModule,
    SharedModule,
    PagesRoutingModule,
    SimplebarAngularModule,
    // CarouselModule,
    FeatherModule.pick(allIcons),
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    LightboxModule,
    LeafletModule,
    FormsModule,
    NgbPaginationModule,
    LoaderModule,
    NgbModule
  ],
  exports: []
})
export class PagesModule {
}
