import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
// import { BehaviorSubject } from 'rxjs';
import {ApiService} from 'src/app/core/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {requests} from 'src/app/core/config/config';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {TYPE} from "../Types";
import {StageEnum} from "../../pages/applications/app-details/stage.enum";
import {StatusEnum} from "../../enums/Statuses.enum";
import {CommonStore} from "../../core/services/common.store";
import {Permissions} from "../../core/data/permissions";

@Component({
  selector: 'assign-to',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './assign-to.component.html',
  styleUrls: ['./assign-to.component.scss']
})
export class AssignToComponent implements OnInit {
  @Input() type: TYPE;
  @Output() onAssign: EventEmitter<any> = new EventEmitter(false);
  allDetails: any;
  assignApplication: string = "";
  assignForm: FormGroup;
  assignToInfo: any
  assignee: string;
  follower: string;
  currentLanguage: string = "EN";
  departments: any;
  id: any
  permission = Permissions;
  user: any;
  users: any;
  followedByError: boolean = false;
  assigneeError: boolean = false;
  protected readonly StageEnum = StageEnum;

  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private translate: TranslateService,
              public commonStore: CommonStore) {

  }

  ngOnInit(): void {

    this.initForm();
    this.user = JSON.parse(localStorage.getItem('admin')).user;
    this.id = +this.activatedRoute.snapshot.params['id'];
    if (this.id) {
      this.fetch();
    }
    this.currentLanguage = this.translate.currentLang.toUpperCase();
    this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang.toUpperCase();
    });
    this.getDepartments();
    this.getAllUsers();
  }

  getDepartments() {
    this.apiService.sendRequest(requests.getAllDepartments, 'get',).subscribe((res: any) => {
      this.departments = res.data.rows;
      console.log(this.departments)
    })
  }

  getAllUsers() {
    this.apiService.sendRequest(requests.getAllAdminUsers, 'get', {limit: 100, offset: 0}).subscribe((res: any) => {
      this.users = res.data.rows;
      this.assignForm.patchValue({
        userId: null
      })
      const fileControl = this.assignForm.get('userId');
      fileControl.updateValueAndValidity();
    })


  }

  getFilteredUsers() {
    if (this.assignForm.value.departmentId) {
      return this.users?.filter(u => u.department?.id == this.assignForm.value.departmentId)
    } else {
      return [];
    }
  }

  getCustomerServiceUsers() {
    return this.users?.filter(user => user.department?.id == 11)
  }

  // concatenateName(user){
  //   if(user.firstName != null && user.lastName != null){
  //     return user.firstName + " "+ user.lastName;
  //   }else{
  //         return "";
  //   }

  // }
  onDepartment(event) {
    console.log("value: " + event.target.value);
    this.assignForm.patchValue({
      userId: null
    })
    // to update form to check for validation error
    const fileControl = this.assignForm.get('userId');
    fileControl.setValue([''])
    fileControl.setValidators([Validators.required])
    fileControl.updateValueAndValidity();

  }

  concatenateName(assignee) {
    if (assignee?.firstName != null && assignee?.lastName != null) {
      return assignee.firstName + " " + (assignee.middleName || '') + assignee.lastName;
    } else {
      return "";
    }
  }

  assign(assignToMe: boolean) {
    if (assignToMe) {
      this.assignForm.value.userId = this.user.id;
    }
    switch (this.type) {
      case TYPE.APPLICATION:
        this.assignData(requests.assignApplication, 'put');
        break;
      case TYPE.REPORT:
        this.assignData(requests.assignReport, 'post', "/assign-department");
        break;
      case TYPE.COMPLAINT_SUGGESTION:
        this.assignData(requests.assignComplaintAndSuggestion, 'post', "/assign-department");
        break;
    }
  }

  setValidators(fields: string[], validators: any[]) {
    fields.forEach(field => {
      this.assignForm.get(field).setValidators(validators);
    });
    this.assignForm.updateValueAndValidity();
  }

  onAssignClick() {
    this.setValidators(['userId'], [Validators.required]);
    this.assign(false);
  }

  onAssignToMeClick() {
    // this.setValidators(['followedBy'], [Validators.required]);
    this.assign(true);
  }

  private initForm() {
    this.assignForm = this.fb.group({
      departmentId: [null, [Validators.required]],
      userId: [null, [Validators.required]],
      followedBy: [null, []]
    })
  }

  private assignData(apiEndpoint: string, method: string, suffix = '') {
    let body = {
      assignedTo: +this.assignForm.value.userId,
      departmentId: 11,
      followedBy: this.assignForm.value.followedBy ? +this.assignForm.value.followedBy : null
    };

    this.assigneeError = !body.assignedTo;
    this.followedByError = !body.followedBy;

    // if (this.assigneeError || this.followedByError) {
    if (this.assigneeError) {
      return;
    }

    if (this.allDetails?.assignee) {
      this.apiService.sendRequest(apiEndpoint + this.id + suffix, method, body).subscribe((res: any) => {
        this.assignToInfo = res;
        this.onAssign.emit(true);
        this.fetch();
      });
    } else {
      this.apiService.sendRequest(apiEndpoint + this.id + suffix, method, body).subscribe((res: any) => {
        this.assignToInfo = res;
        this.apiService.sendRequest(requests.changeApplicationStatus + this.id, 'put', {
          statusId: StatusEnum.PENDING,
          internalStatusId: StatusEnum.ASSIGNED,
          customerStatusId: StatusEnum.PENDING_REVIEW,
          stage: StageEnum.APPLICATION,
        })
          .subscribe((res: any) => {
            this.onAssign.emit(true);
            this.fetch();
            console.log(res);
          })
      });
    }
  }

  private fetchData(apiEndpoint: string) {
    this.apiService.sendRequest(apiEndpoint + this.id, 'get').subscribe((res: any) => {
      console.log("Department Assignee", res.data);
      this.allDetails = res.data;
      this.assignee = (this.allDetails?.assignee ? this.allDetails?.assignee?.firstName + ' ' + this.allDetails?.assignee?.lastName : "");
      this.follower = (this.allDetails?.follower ? this.allDetails?.follower?.firstName + ' ' + this.allDetails?.follower?.lastName : "");
      this.assignForm.patchValue({
        departmentId: [res.data?.departmentId],
        userId: [res.data?.assignedTo],
        followedBy: [res.data?.followedBy]
      });
    });
  }

  private fetch() {
    switch (this.type) {
      case TYPE.APPLICATION:
        this.fetchData(requests.getApplicationDetailsByid);
        this.assignApplication = "ASSIGN_APPLICATION";
        break;
      case TYPE.REPORT:
        this.fetchData(requests.getInstantreportsById);
        this.assignApplication = "ASSIGN_REPORT";
        break;
      case TYPE.COMPLAINT_SUGGESTION:
        this.fetchData(requests.getComplaintAndSuggestionById);
        this.assignApplication = "ASSIGN_COMPLAINT_SUGGESTION";
        break;
    }
  }
}
