import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {CookieService} from "ngx-cookie-service";
import {LanguageService} from "./core/services/language.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'المنصة الإدارية لكلباء الذكية';

  constructor(private translate: TranslateService,
              private titleService: Title,
              public languageService: LanguageService,
              private cookieService: CookieService) {
  }

  ngOnInit() {
    let storedLang = this.cookieService.get('lang')
    if (!storedLang) {
      this.languageService.setLanguage('ar');
    }
    this.updateTranslation();

    this.translate.onLangChange.subscribe(() => {
      this.updateTranslation();
    });
  }

  private updateTranslation(): void {
    this.translate.get([
      "SMART_KALBA_ADMIN_PANEL"
    ]).subscribe(translations => {
      this.title = translations["SMART_KALBA_ADMIN_PANEL"];
      this.setTitle(this.title);
    });
  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
