import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translatePipe',

})
export class Translate implements PipeTransform {
    // specify every argument individually
    // or use a rest parameter
    transform(data: any, lang: any, property: any): any {
        return data && data.find(x=>x.languageCode==lang)[property];
     }
}
