import {Permissions} from 'src/app/core/data/permissions';
import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'monitor',
    link: '/',
    common: true,
  },
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    common: true

  },
  {
    id: 123456,
    label: 'MENUITEMS.APPS.LIST.APPLICATIONS',
    icon: 'file-text',
    link: '/applications',
    common: true,
    permission: Permissions.application_view,
    subItems: [
      {
        id: 786868,
        label: 'MENUITEMS.APPS.LIST.ALLSERVICES',
        link: '/applications',
        parentId: 123456,
        permission: Permissions.application_view,
      },
      {
        id: 4,
        label: 'MENUITEMS.APPS.LIST.INSTANTREPORTS',
        icon: 'tool',
        link: '/instant-reports',
        parentId: 123456,
        permission: Permissions.instant_report_view
      },

    ]
  },

  {
    id: 123456,
    label: 'MENUITEMS.APPS.LIST.CUSTOMERSTAISFACTION',
    icon: 'smile',
    link: '/applications',
    common: true,
    permission: Permissions.customer_satisfaction,
    subItems: [
      {
        id: 563,
        parentId: 123456,
        label: 'MENUITEMS.APPS.LIST.COMPLAINTS',
        icon: 'alert-triangle',
        link: '/complaints',
        permission: Permissions.customer_satisfaction,
        common: true
      },
      {
        id: 2323,
        parentId: 123456,
        label: 'MENUITEMS.APPS.LIST.EXPRESSIONS',
        icon: ' bx bx-smile',
        link: '/expressions',
        common: true,
        permission: Permissions.customer_satisfaction
      },
    ]
  },
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    common: true

  },
  {
    id: 4,
    label: 'MENUITEMS.APPS.LIST.SERVICESCATALOGUE',
    icon: 'check-circle',
    permission: Permissions.service_catalogue,
    subItems: [
      {
        id: 4353,
        label: 'MENUITEMS.APPS.LIST.APPLICATIONSLIST',
        link: '/services',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
      {
        id: 4378783,
        label: 'MENUITEMS.APPS.LIST.ADD',
        link: '/services/add',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
      {
        id: 4354,
        label: 'MENUITEMS.APPS.LIST.ALLSERVICECATEGORIES',
        link: '/services/service-categories',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
      {
        id: 4454,
        label: 'MENUITEMS.APPS.LIST.ADD_SERVICE_CATEGORY',
        link: '/services/add-category',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
      {
        id: 4554,
        label: 'MENUITEMS.APPS.LIST.TEHSEEL_SERVICES',
        link: '/services/tehseel-services',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
      {
        id: 4654,
        label: 'MENUITEMS.APPS.LIST.ADD_TEHSEEL_SERVICE',
        link: '/services/tehseel-service/add',
        parentId: 4,
        permission: Permissions.service_catalogue
      },
    ]
  },
  {
    id: 4343434,
    label: 'MENUITEMS.APPS.LIST.MEDIA',
    icon: 'edit-3',
    common: false,
    permission: Permissions.media,
    subItems: [
      {
        id: 3,
        label: 'MENUITEMS.APPS.LIST.COMMUNITY',
        icon: 'users',
        permission: Permissions.media,
        subItems: [
          {
            id: 4,
            label: 'MENUITEMS.APPS.LIST.MANAGECOVERS',
            link: 'cover-images',
            parentId: 3,
            permission: Permissions.media

          },
          {
            id: 4,
            label: 'MENUITEMS.APPS.LIST.NEWS',
            link: 'news',
            parentId: 3,
            permission: Permissions.media
          },
          {
            id: 5,
            label: 'MENUITEMS.APPS.LIST.HOLIDAYS',
            link: 'holidays',
            parentId: 3,
            permission: Permissions.media
          },
          {
            id: 6,
            label: 'MENUITEMS.APPS.LIST.EVENTS',
            link: 'events',
            parentId: 3,
            permission: Permissions.media
          },
          {
            id: 7,
            label: 'MENUITEMS.APPS.LIST.DESTINATIONS',
            link: 'destinations',
            parentId: 3,
            permission: Permissions.media
          }]
      },
      {
        id: 578,
        label: 'MENUITEMS.APPS.LIST.ABOUTKALBA',
        icon: 'info',
        parentId: 4343434,
        link: 'about-kalba',
        permission: Permissions.media
      },
      {
        id: 3412,
        label: 'MENUITEMS.APPS.LIST.LOCATIONSLIST',
        link: 'locations',
        parentId: 4343434,
        permission: Permissions.media
      },
      {
        id: 342213,
        label: 'MENUITEMS.APPS.LIST.FAQ',
        link: '/settings/faq-listing',
        parentId: 4343434,
        permission: Permissions.media
      },
      {
        id: 342213,
        label: 'MENUITEMS.APPS.LIST.WEBSITE',
        link: '/settings',
        parentId: 4343434,
        permission: Permissions.media
      },
      {
        id: 342213,
        label: 'MENUITEMS.APPS.LIST.LANDINGPAGE',
        link: '/settings/video',
        parentId: 4343434,
        permission: Permissions.setting
      },
    ]
  },
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    common: true

  },
  {
    id: 3,
    label: 'MENUITEMS.APPS.TEXT',
    icon: 'users',
    link: 'customers',
    permission: Permissions.customers,
    // subItems: [
    //     {
    //         id: 4,
    //         label: 'MENUITEMS.APPS.LIST.BUSINESS',
    //         link: 'business',
    //         parentId: 3,
    //         permission: Permissions.business_user_view
    //     },
    //     {
    //         id: 5,
    //         label: 'MENUITEMS.APPS.LIST.GOVERNMENT',
    //         link: 'gov',
    //         parentId: 3,
    //         permission: Permissions.government_user_detail_view
    //
    //     },
    //     {
    //         id: 5,
    //         label: 'MENUITEMS.APPS.LIST.INDIVIDUAL',
    //         link: 'ind',
    //         parentId: 3,
    //         permission: Permissions.individual_user_view
    //
    //     }
    // ]
  },

  {
    id: 7554,
    label: 'MENUITEMS.APPS.LIST.ADMINISTRATION',
    icon: 'user-check',
    link: '/admins',
    permission: Permissions.employees,
    subItems: [
      // {
      //     id: 342213,
      //     label: 'MENUITEMS.APPS.LIST.ROLES',
      //     link: '/admins/roles',
      //     parentId: 7554,
      //     permission: Permissions.Roles
      // },
      {
        id: 34455,
        label: 'MENUITEMS.APPS.LIST.DEPARTMENTS',
        link: '/admins/departments',
        parentId: 7554,
        permission: Permissions.employees
      },
      {
        id: 344656,
        label: 'MENUITEMS.APPS.LIST.ADMIN',
        link: '/admins',
        parentId: 7554,
        permission: Permissions.employees
      },
      {
        id: 34465346,
        label: 'MENUITEMS.APPS.LIST.ADDADMIN',
        link: '/admins/add-user',
        parentId: 7554,
        permission: Permissions.employees
      },
    ]

  },

  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    common: true

  },


  {
    id: 4,
    label: 'MENUITEMS.APPS.LIST.SETTINGS',
    icon: 'settings',
    link: '/settings',
    permission: Permissions.setting,
    subItems: [
      // {
      //     id: 578,
      //     label: 'MENUITEMS.APPS.LIST.ABOUTKALBA',
      //     link: '/about-kalba',
      //     permission: Permissions.settings_view
      // },


      {
        id: 4521,
        label: 'MENUITEMS.APPS.LIST.UTILITIES',
        link: '/utilities',
        parentId: 4,
        permission: Permissions.setting
      },
    ]

  },

];

