import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/api.service';
import { requests } from 'src/app/core/config/config';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  applicationsStats: any;
  applicationStatus:any;

  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    this.apiService.sendRequest(requests.applicationStats, 'get').subscribe((res:any) => {
      this.applicationsStats = res?.data
      this.applicationStatus = this.applicationsStats?.applicationsCountByStatus.filter(item => item.status.status !== "Draft").map(item => ({status: item.status.status, count: item.count}));
    })
  }

}
