export enum Permissions {
  // New Permissions
  application_view = "application_view",
  application_assign = "application_assign",
  application_assign_to_me = "application_assign_to_me",
  application_verify = "application_verify",
  application_request_update_info = "application_request_update_info",
  application_request_payment = "application_request_payment",
  application_request_service_processing = "application_request_service_processing",
  application_submit_for_review = "application_submit_for_review",
  application_complete = "application_complete",
  instant_report_view = "instant_report_view",
  instant_report_complete = "instant_report_complete",
  customer_satisfaction = "customer_satisfaction",
  service_catalogue = "service_catalogue",
  media = "media",
  customers = "customers",
  employees = "employees",
  setting = "setting"
}
