<div class="row" *ngIf="applicationsStats">
    <div class="col-xl-4 col-md-6">
      <!-- card -->
      <div class="card card-h-100">
        <!-- card body -->
        <div class="card-body">
          <div class="row align-items-center">
            <div class="">
                          <span class="text-muted mb-3 pb-3 lh-1 d-block text-truncate">
                            <h4>{{ 'TOTAL_APPLICATIONS' | translate }}
                              <b><span class="counter-value" [CountTo]="applicationsStats?.totalApplications" [from]="0"
                                    [duration]="1"></span></b>
                              </h4>
                          </span>
  
              <!-- <h4 class="mb-3">
                  <span [CountTo]="applicationsStats?.totalApplications" class="counter-value" [from]="0"
                      [duration]="1"></span>
              </h4> -->
            </div>
            <div *ngFor="let data of applicationStatus">
              <!-- <span class="text-muted mb-3 lh-1 d-block text-truncate">Total Applications
                  <span class="p-2 font-size-14 fw-bold" [CountTo]="applicationsStats?.totalApplications"
                      [from]="0" [duration]="1"></span>
              </span> -->
              <div class="align-items-center">
                <div class="">
                                  <span class="text-muted mb-4 d-block text-truncate">
                                    {{ (data?.status | uppercase | replace:' ':'_') + '_APPLICATIONS' | translate }}
                                    <b><span [CountTo]="data?.count" class="counter-value mx-2" [from]="0" [duration]="1" ></span></b>
                                  </span>
  
                </div>
              </div>
            </div>
  
            <!-- <div class="col-6">
                <apx-chart class="apex-charts" dir="ltr" [series]="walletlineChart.series"
                    [chart]="walletlineChart.chart" [colors]="walletlineChart.colors"
                    [stroke]="walletlineChart.stroke" [tooltip]="walletlineChart.tooltip">
                </apx-chart>
            </div> -->
          </div>
          <!-- <div class="text-nowrap">
              <span class="badge bg-soft-success text-success">+$20.9k</span>
              <span class="ms-1 text-muted font-size-13">Since last week</span>
          </div> -->
        </div><!-- end card body -->
      </div><!-- end card -->
    </div><!-- end col -->
  
    <div class="col-xl-4 col-md-6">
      <!-- card -->
      <div class="card card-h-100">
        <!-- card body -->
        <div class="card-body">
          <div class="row align-items-center">
            <div class="">
                          <span class="text-muted mb-3 pb-3 lh-1 d-block text-truncate">
                            <h4>{{ 'TOTAL_SERVICES' | translate}}
                              <b><span class="counter-value" [CountTo]="analytics?.totalServices" [from]="0"
                                    [duration]="1"></span></b>
                              </h4>
                          </span>
  
              <!-- <h4 class="mb-3">
                  <span [CountTo]="applicationsStats?.totalApplications" class="counter-value" [from]="0"
                      [duration]="1"></span>
              </h4> -->
            </div>
            <div *ngFor="let data of analytics?.serviceCountByUserType">
              <!-- <span class="text-muted mb-3 lh-1 d-block text-truncate">Total Applications
                  <span class="p-2 font-size-14 fw-bold" [CountTo]="applicationsStats?.totalApplications"
                      [from]="0" [duration]="1"></span>
              </span> -->
              <div class="align-items-center">
                <div class="">
                                  <span class="text-muted mb-4 lh-1 d-block text-truncate">
                                    {{ (data?.userType?.type | uppercase | replace:' ':'_') + '_SERVICES' | translate }}
  
                  <b>
                    <span [CountTo]="data?.count" class="counter-value mx-2" [from]="0" [duration]="1"></span>
                  </b>
                                    </span>
                </div>
              </div>
            </div>
            <div *ngFor="let data of analytics?.servicesCountByStatus">
              <!-- <span class="text-muted mb-3 lh-1 d-block text-truncate">Total Applications
                  <span class="p-2 font-size-14 fw-bold" [CountTo]="applicationsStats?.totalApplications"
                      [from]="0" [duration]="1"></span>
              </span> -->
              <div class="align-items-center">
                <div class="col-6">
                                  <span class="text-muted mb-4 lh-1 d-block text-truncate">
                                    {{ (data?.status?.status | uppercase | replace:' ':'_') + '_SERVICES' | translate }}
  
                  <b>
                    <span [CountTo]="data?.count" class="counter-value mx-2" [from]="0" [duration]="1"></span>
                  </b>
                                    </span>
                </div>
              </div>
            </div>
  
            <!-- <div class="col-6">
                <apx-chart class="apex-charts" dir="ltr" [series]="walletlineChart.series"
                    [chart]="walletlineChart.chart" [colors]="walletlineChart.colors"
                    [stroke]="walletlineChart.stroke" [tooltip]="walletlineChart.tooltip">
                </apx-chart>
            </div> -->
          </div>
          <!-- <div class="text-nowrap">
              <span class="badge bg-soft-success text-success">+$20.9k</span>
              <span class="ms-1 text-muted font-size-13">Since last week</span>
          </div> -->
        </div><!-- end card body -->
      </div><!-- end card -->
    </div>
  
    <div class="col-xl-4 col-md-6">
      <div class="card card-h-100">
        <!-- card body -->
        <div class="card-body">
          <div *ngIf="reportStats">
            <div class="row align-items-center">
                      <span class="text-muted mb-3 lh-1 d-block text-truncate">
                        <h4>{{ 'TOTAL_INSTANT_REPORTS' | translate }}
                          <b> <span [CountTo]="reportStats?.totalInstantReports" class="counter-value" [from]="0"
                                [duration]="1"></span></b>
                      </h4>
                        </span>
  
              <!-- <div class="col-6">
                  <apx-chart class="apex-charts" dir="ltr" [series]="walletlineChart.series"
                      [chart]="walletlineChart.chart" [colors]="walletlineChart.colors"
                      [stroke]="walletlineChart.stroke" [tooltip]="walletlineChart.tooltip">
                  </apx-chart>
              </div> -->
            </div>
  <!--          <div class="text-nowrap">-->
  <!--            &lt;!&ndash; <span class="badge bg-soft-success text-success">+$20.9k</span>-->
  <!--            <span class="ms-1 text-muted font-size-13">Since last week</span> &ndash;&gt;-->
  <!--          </div>-->
  
            <div class="" *ngFor="let data of reportStats?.instantReportCountByStatus">
  
              <div class="row align-items-center">
                <div class="">
                          <span class="text-muted mb-4 lh-1 d-block text-truncate">
                            {{ (data?.status?.status | uppercase | replace:' ':'_') + '_REPORTS' | translate }}
  
                  <b>
                    <span [CountTo]="data?.count" class="counter-value mx-2" [from]="0" [duration]="1"></span>
                  </b>
                            </span>
                </div>
  
                <!-- <div class="col-6">
                    <apx-chart class="apex-charts" dir="ltr" [series]="walletlineChart.series"
                        [chart]="walletlineChart.chart" [colors]="walletlineChart.colors"
                        [stroke]="walletlineChart.stroke" [tooltip]="walletlineChart.tooltip">
                    </apx-chart>
                </div> -->
              </div>
              <div class="text-nowrap">
                <!-- <span class="badge bg-soft-success text-success">+$20.9k</span>
                <span class="ms-1 text-muted font-size-13">Since last week</span> -->
              </div>
            </div><!-- end col -->
          </div>
        </div>
      </div>
    </div>
  
    <!-- <div class="col-xl-4 col-md-6" *ngFor="let data of applicationsStats?.applicationsCountByStatus">
  
        <div class="card card-h-100">
  
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-6">
                        <span class="text-muted mb-3 lh-1 d-block text-truncate">{{data?.status?.status | titlecase}}
                            Reports</span>
                        <h4 class="mb-3">
                            <span [CountTo]="data?.count" class="counter-value" [from]="0" [duration]="1"></span>
                        </h4>
                    </div>
                </div>
                <div class="text-nowrap">
                    <span class="badge bg-soft-success text-success">+$20.9k</span>
                    <span class="ms-1 text-muted font-size-13">Since last week</span>
                </div>
            </div>
        </div>
    </div> -->
  </div>