<div class="mt-4 mb-4">
  <div class="row">
    <div class="col-md-6">
      <div class="card" style="height:600px; overflow-y:auto">
        <div class="card-body">
          <iframe src="https://www.instagram.com/kalbamun/embed" width="100%" height="100%" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
        </div>
      </div>
    </div>
    <div class="col-md-6" >
      <div class="card" style="height:600px; overflow-y:auto">
        <div class="card-body">
          <a class="twitter-timeline" href="https://twitter.com/KalbaMun?ref_src=twsrc%5Etfw"></a>
        </div>
      </div>
    </div>
  </div>
</div>
